import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import { AdWrapper } from 'adWrapper';

import '@happydemics/design_system';

import { App } from 'components/app';

import './index.scss';

ReactDOM.render(
  <AdWrapper>
    <App />
  </AdWrapper>,
  document.getElementById('root')
);
