import { useCallback, useContext } from 'react';

import { ParametersContext } from '../contexts/parametersContext';

export interface Message {
  payload?: {} | undefined;
  source?: string;
  type: string;
}

export const useAdWrapper = () => {
  const { adWrapperCore } = useContext(ParametersContext);

  /**
   * Sends a message to the AdWrapper, if it exists, via a postMessage
   * @param {string} eventMessage - The message to post to the AdWrapper
   */
  const notifyAdWrapper = useCallback(
    (event: string | Message) => {
      const message: Message =
        typeof event === 'string' ? { type: event } : event;

      message.source = 'voting-interface';

      if (adWrapperCore) {
        adWrapperCore.postMessage(message, '*');
      }
    },
    [adWrapperCore]
  );

  return { notifyAdWrapper };
};
