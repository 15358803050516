import { MutableRefObject, useEffect } from 'react';

import _ from 'lodash';

/**
 * On safari, the audio is only played when a user event is triggered so here we trigger the event on first touch/click
 * 1. Preload : trigger first user event and prepare audio (play, pause, rewind to the start)
 * 2. When question is active, launch audio with play()
 * Documentation : https://curtisrobinson.medium.com/how-to-auto-play-audio-in-safari-with-javascript-21d50b0a2765
 */

export const useAutoPlay = ({
  active,
  playerRef: { current: player },
}: {
  active: Boolean;
  playerRef: MutableRefObject<HTMLVideoElement | null>;
}) => {
  useEffect(() => {
    if (player === null) return;

    const surveyScreen = document.getElementById('survey-screen');

    const preloadAudio = () => {
      player.play();
      player.pause();
      _.assignIn(player, { currentTime: 0 });
    };
    if (surveyScreen) {
      // on mobile
      surveyScreen.addEventListener('touchstart', () => preloadAudio(), {
        once: true,
      });
      // on desktop
      surveyScreen.addEventListener('click', () => preloadAudio(), {
        once: true,
      });
    }
    if (active) player.play();
    else player.pause();
  }, [active, player]);
};
