/* eslint-disable react/no-array-index-key */
import { memo } from 'react';

import cn from 'classnames';

import { DEFAULT_VERBATIM_ANSWERS } from 'constant/verbatimAnswer';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';
import { useTranslations } from 'hooks/useTranslations';

import { Media } from '../media';
import { TextAnswerItem } from '../shared/answerItems/textAnswerItems/textAnswerItem/TextAnswerItem';
import { Question } from '../shared/question';
import { Verbatim } from './verbatim';

import { useVerbatimQuestion } from './useVerbatimQuestion';

import styles from './verbatimQuestion.module.scss';

type VerbatimQuestionProps = {
  active: boolean;
  audio?: string;
  audioType?: string;
  body: string;
  hint?: string;
  image?: string;
  multipleVerbatim: boolean;
  selectVerbatim: (answers: string[]) => void;
  submitVote: () => void;
  video?: string;
};

function VerbatimQuestion({
  active,
  audio,
  audioType,
  body,
  hint,
  image,
  multipleVerbatim,
  selectVerbatim,
  submitVote,
  video,
}: VerbatimQuestionProps) {
  const { t } = useTranslations();

  const {
    defaultAnswer,
    focused,
    handleVerbatimChange,
    setDefaultAnswer,
    setFocused,
    verbatims,
  } = useVerbatimQuestion({
    multipleVerbatim,
    selectVerbatim,
  });
  const { isLandscape } = useScreenOrientation();

  const hasMedia = audio || video || image;

  return (
    <Question
      body={body}
      hint={hint}
      className={cn(styles.verbatimContainer, {
        [styles.twoColumns]: hasMedia && isLandscape,
      })}
    >
      <Media
        active={active}
        audio={audio}
        audioType={audioType}
        image={image}
        video={video}
      />
      <div className={styles.gridContainer}>
        <hdm-grid
          class={cn(styles.verbatimAnswers, {
            [styles.twoColumns]: !hasMedia && isLandscape,
          })}
        >
          <hdm-stack>
            {verbatims.map((verbatim, i) => (
              <Verbatim
                isFocused={active && !defaultAnswer && i === focused}
                key={i}
                multipleVerbatim
                onChange={handleVerbatimChange}
                onFocus={() => {
                  setFocused(i);
                }}
                onSubmit={() => {
                  if (multipleVerbatim) setFocused(focused + 1);
                  else submitVote();
                }}
                position={i}
                verbatim={verbatim}
              />
            ))}
          </hdm-stack>
          <hdm-stack>
            {DEFAULT_VERBATIM_ANSWERS.map(id => (
              <TextAnswerItem
                answer={
                  { anchored: false, body: t(id), id } as unknown as AnswerVI
                }
                key={id}
                selectAnswer={setDefaultAnswer}
                selectedAnswer={[defaultAnswer]}
              />
            ))}
          </hdm-stack>
        </hdm-grid>
      </div>
    </Question>
  );
}

const MemoVerbatimQuestion = memo(VerbatimQuestion);

export { MemoVerbatimQuestion as VerbatimQuestion };
