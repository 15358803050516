import { memo } from 'react';

import cn from 'classnames';

import { ReactComponent as ArrowNavigationIcon } from 'assets/img/icons/arrow_navigation.svg';

import styles from './navigationButton.module.scss';

export enum BUTTON_TYPE {
  next = 'next',
  previous = 'previous',
}

type NavigationButtonProps = {
  type: BUTTON_TYPE;
  navigate: () => void;
  hidden: boolean;
  disabled?: boolean;
};

function NavigationButton({
  type,
  navigate,
  hidden,
  disabled = false,
}: NavigationButtonProps) {
  return (
    <button
      data-testid={`button-navigation-${type}`}
      id={`button-navigation-${type}`}
      className={cn(styles.buttonNavigation, {
        [styles.hidden]: hidden,
        [styles.isNext]: type === BUTTON_TYPE.next,
      })}
      disabled={disabled}
      type="button"
      onClick={navigate}
    >
      <ArrowNavigationIcon />
    </button>
  );
}

const MemoNavigationButton = memo(NavigationButton);

export { MemoNavigationButton as NavigationButton };
