import { AnswerVI, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

type ClearInconsistenciesProps = {
  newRecord: any[];
  originQuestionId: number;
  questions: QuestionVI[];
  records: {
    [key: string]: any;
  };
};

export const clearInconsistencies = ({
  newRecord,
  originQuestionId,
  questions,
  records,
}: ClearInconsistenciesProps) => {
  const isConsistent = (
    parentId: number | null,
    parentDimensionId: number | null
  ) => {
    if (!parentId) return true;

    const originQuestion = questions.find(({ id }) => originQuestionId === id);

    if (!originQuestion) return true;

    const { answers: originAnswers } = originQuestion;

    if (!originAnswers.some(({ id }) => parentId === id)) return true;

    const { questionType: originQuestionType } = originQuestion;

    if (originQuestionType !== QUESTION_TYPES.matrix) {
      return newRecord.includes(parentId);
    }

    if (!parentDimensionId) return true;

    const { dimensions: originDimensions = [] } = originQuestion;

    if (!originDimensions.some(({ id }) => parentDimensionId === id))
      return true;

    const newDimensionRecord = newRecord[parentDimensionId] || [];

    return newDimensionRecord.includes(parentId);
  };

  const clearInconsistenciesFromMulti = ({
    answers,
    record,
  }: {
    answers: AnswerVI[];
    record: any[];
  }) =>
    record.filter((answerId: number) => {
      const answer = answers.find(({ id }) => answerId === id);

      if (!answer) return record;

      const { parentId, parentDimensionId } = answer;

      return isConsistent(parentId, parentDimensionId);
    });

  const clearInconsistenciesFromMatrix = ({
    answers,
    record,
  }: {
    answers: AnswerVI[];
    record: any[];
  }) =>
    Object.entries(record).reduce((acc, [dimensionId, itemIds]) => {
      acc[dimensionId] = itemIds.filter((answerId: number) => {
        const answer = answers.find(({ id }) => answerId === id);

        if (!answer) return true;

        const { parentId, parentDimensionId } = answer;

        return isConsistent(parentId, parentDimensionId);
      });

      return acc;
    }, {} as { [dimensionId: string]: number[] });

  const clearInconsistenciesFromRadar = ({
    answers,
    record,
  }: {
    answers: AnswerVI[];
    record: any[];
  }) =>
    Object.entries(record).reduce((acc, [answerIdStr, value]) => {
      const answerId = parseInt(answerIdStr, 10);

      const answer = answers.find(({ id }) => answerId === id);

      if (!answer) {
        acc[answerId] = value;
        return acc;
      }

      const { parentId, parentDimensionId } = answer;

      if (isConsistent(parentId, parentDimensionId)) {
        acc[answerId] = value;
      }

      return acc;
    }, {} as { [answerId: string]: any });

  return Object.entries(records).reduce(
    (acc, [questionIdStr, record]) => {
      const questionId = parseInt(questionIdStr, 10);

      if (questionId === originQuestionId) return acc;

      const question = questions.find(({ id }) => questionId === id);

      if (!question) return acc;

      const { answers, questionType } = question;

      let clearedRecord;
      switch (questionType) {
        case QUESTION_TYPES.simple:
        case QUESTION_TYPES.multi:
        case QUESTION_TYPES.ranking:
          clearedRecord = clearInconsistenciesFromMulti({
            answers,
            record,
          });
          break;
        case QUESTION_TYPES.matrix:
          clearedRecord = clearInconsistenciesFromMatrix({
            answers,
            record,
          });
          break;
        case QUESTION_TYPES.radar:
        case QUESTION_TYPES.wordPairs:
          clearedRecord = clearInconsistenciesFromRadar({
            answers,
            record,
          });
          break;
        default:
      }

      acc[questionId] = clearedRecord || record;

      return acc;
    },
    {
      ...records,
      [originQuestionId]: newRecord,
    } as { [questionId: string]: any }
  );
};
