import { useEffect, useState } from 'react';

export const useDimensions = ({ index, dimensions }: any) => {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [currentDimension, setCurrentDimension] = useState(dimensions[index]);

  const [hide, setHide] = useState(false);
  const [scaleDown, setScaleDown] = useState(false);

  /**
   * Orchestrate the animation when transiting from one sub-question to the other.
   */
  useEffect(() => {
    if (index === currentIndex) return;

    const movingForward = currentIndex < index;

    setTimeout(() => setHide(true), 50);

    if (movingForward) {
      setScaleDown(true);
    }

    setTimeout(() => {
      setCurrentIndex(index);
      setCurrentDimension(dimensions[index]);
      setScaleDown(!movingForward);

      setTimeout(() => {
        setHide(false);

        if (!movingForward) {
          setScaleDown(false);
        }
      }, 150);
    }, 100);
  }, [currentIndex, index, dimensions]);

  return { currentDimension, hide, scaleDown };
};
