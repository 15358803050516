import { useTranslations } from 'hooks/useTranslations';

import styles from '../buttons.module.scss';

type CloseButtonProps = {
  onClick: () => void;
};

export function CloseButton({ onClick }: CloseButtonProps) {
  const { t } = useTranslations();

  return (
    <div className={styles.fullWidth}>
      <button className={styles.buttonLink} type="button" onClick={onClick}>
        {t('close') || 'Close'}
      </button>
    </div>
  );
}
