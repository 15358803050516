// simple fisher-yates implementation
export const shuffle = <T>(deck: T[] = []): T[] => {
  const randomizedDeck: T[] = [];
  const array: T[] = [...deck];
  while (array.length !== 0) {
    const rIndex = Math.floor(array.length * Math.random());
    randomizedDeck.push(array[rIndex]);
    array.splice(rIndex, 1);
  }
  return randomizedDeck;
};
