import React, { memo } from 'react';

import { PixelImage } from './pixelImage';
import { ApiProps } from './types';

import { useApi } from './useApi';

function Api({ apiUrl, children, id, preview, visitToken }: ApiProps) {
  const { pixelImageData, sendData, skipData } = useApi({
    apiUrl,
    id,
    visitToken,
  });

  return (
    <>
      {children({ sendData, skipData })}
      {!preview &&
        Object.entries(pixelImageData).map(([key, src]) => (
          <PixelImage key={key} src={src} />
        ))}
    </>
  );
}

const MemoApi = memo(Api);

export { MemoApi as Api };
