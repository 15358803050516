import { useCallback, useState } from 'react';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

export const useDimensionsNavigation = (questions: QuestionVI[]) => {
  const [currentDimensionIndexes, setCurrentDimensionIndexes] = useState(
    questions.reduce((acc, { questionType }, index) => {
      if (questionType === QUESTION_TYPES.matrix) acc[index] = 0;
      return acc;
    }, {} as { [key: string]: number })
  );

  const isOnEndEdge = useCallback(
    questionIndex => {
      const { dimensions = [] } = questions[questionIndex];

      return currentDimensionIndexes[questionIndex] === dimensions.length - 1;
    },
    [currentDimensionIndexes, questions]
  );

  const isOnStartEdge = useCallback(
    questionIndex => {
      return currentDimensionIndexes[questionIndex] === 0;
    },
    [currentDimensionIndexes]
  );

  const goToNextDimension = useCallback(
    questionIndex => {
      setCurrentDimensionIndexes({
        ...currentDimensionIndexes,
        [questionIndex]: currentDimensionIndexes[questionIndex] + 1,
      });
    },
    [currentDimensionIndexes]
  );

  const goToPreviousDimension = useCallback(
    (questionIndex: number) => {
      setCurrentDimensionIndexes({
        ...currentDimensionIndexes,
        [questionIndex]: currentDimensionIndexes[questionIndex] - 1,
      });
    },
    [currentDimensionIndexes]
  );

  return {
    currentDimensionIndexes,
    goToNextDimension,
    goToPreviousDimension,
    isOnEndEdge,
    isOnStartEdge,
  };
};
