import { useCallback, useRef } from 'react';

import cn from 'classnames';

import { useAssetUrl } from 'hooks/useAssetUrl';

import { RankingNumber } from '../../rankingNumber';

import styles from './mediaAnswerItem.module.scss';

type MediaAnswerItemProps = {
  answerId: number;
  body: string;
  className?: string;
  disabled?: boolean;
  isRanking?: boolean;
  image: string;
  selectedAnswer?: number[];
  selectAnswer?: (value: number) => void;
};

export function MediaAnswerItem({
  answerId,
  body,
  className,
  disabled = false,
  isRanking = false,
  image,
  selectedAnswer = [],
  selectAnswer,
}: MediaAnswerItemProps) {
  const src = useAssetUrl(image);

  const ref = useRef<HTMLButtonElement>(null);

  const onClick = useCallback(() => {
    const { current: button } = ref;
    if (button !== null) {
      button.blur();
    }
    if (selectAnswer) selectAnswer(answerId);
  }, [answerId, selectAnswer]);

  const selected = selectedAnswer.includes(answerId);

  return (
    <button
      type="button"
      className={cn(styles.mediaAnswerItem, className, {
        [styles.selected]: selected,
      })}
      ref={ref}
      onClick={onClick}
      disabled={disabled}
    >
      <img alt={body} src={src} draggable="false" />
      {isRanking && selected && (
        <RankingNumber
          selectedAnswer={selectedAnswer}
          answerId={answerId}
          className={styles.mediaRankingNumber}
        />
      )}
    </button>
  );
}
