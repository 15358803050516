import { Dispatch } from 'react';

import ELEMENT from 'constant/element';

import { EmailScreen, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { questionHasConsistentAnswers } from './utils/answerAnalysis';
import { applyScenario, computeSkipValues } from './utils/applyScenario';
import { sendSkip } from './utils/sendSkip';

import { DataFunction } from 'components/api/useApi/types';

import { ScenarioProps } from './types';

export type NextQuestionProps = {
  currentIndex: number;
  currentQuestion: QuestionVI;
  emailScreen?: EmailScreen;
  goToNextDimension: (questionIndex: number) => void;
  isOnEndEdge: (questionIndex: number) => boolean;
  questions: QuestionVI[];
  questionAnswers: { [questionId: string]: any };
  scenarioProps: ScenarioProps;
  setCurrentElement: Dispatch<string>;
  setCurrentIndex: Dispatch<number>;
  setCurrentQuestion: Dispatch<QuestionVI>;
  setPrevReady: Dispatch<boolean>;
  skipData: DataFunction;
};

export const useNextQuestion = () => {
  const findQuestionIdsSkippedByInconsistency = (
    questions: QuestionVI[],
    currentIndex: number,
    newIndex: number
  ) =>
    questions
      .slice(currentIndex + 1, newIndex)
      .filter(
        ({ answers, questionType }: any) =>
          !questionHasConsistentAnswers(answers, questionType)
      )
      .map(({ id }) => id);

  const nextQuestion = ({
    currentIndex,
    currentQuestion,
    emailScreen,
    goToNextDimension,
    isOnEndEdge,
    questions,
    scenarioProps,
    setCurrentElement,
    setCurrentIndex,
    setCurrentQuestion,
    setPrevReady,
    skipData,
  }: NextQuestionProps) => {
    if (
      currentQuestion.questionType === QUESTION_TYPES.matrix &&
      !isOnEndEdge(currentIndex)
    ) {
      goToNextDimension(currentIndex);
      return;
    }

    const allSkip = applyScenario({
      ...scenarioProps,
      currentIndex,
      currentQuestion,
      questions,
      skipData,
    });

    const filteredQuestions = questions.filter(
      ({ answers, id, questionType }) =>
        allSkip.indexOf(id) === -1 &&
        questionHasConsistentAnswers(answers, questionType)
    );
    const idx = filteredQuestions.findIndex(q => q.id === currentQuestion.id);
    const newCurrent = filteredQuestions[idx + 1];

    if (newCurrent) {
      const newIndex = questions.indexOf(newCurrent);

      sendSkip({
        by: 'skip_by_inconsistency',
        ids: findQuestionIdsSkippedByInconsistency(
          questions,
          currentIndex,
          newIndex
        ),
        skipData,
      });

      setCurrentIndex(newIndex);
      setCurrentQuestion(newCurrent);
      setPrevReady(true);
    } else if (emailScreen) {
      setCurrentElement(ELEMENT.participant);
      setCurrentIndex(questions.length);
      setPrevReady(true);
    } else {
      setCurrentElement(ELEMENT.message);
      setCurrentIndex(questions.length);
      setPrevReady(false);
    }
  };

  type GetNextQuestionProps = {
    currentIndex: number;
    currentQuestion: QuestionVI;
    isOnEndEdge: (questionIndex: number) => boolean;
    questions: QuestionVI[];
    scenarioProps: ScenarioProps;
  };

  const getNextQuestion = ({
    currentIndex,
    currentQuestion,
    isOnEndEdge,
    questions,
    scenarioProps,
  }: GetNextQuestionProps) => {
    if (
      currentQuestion.questionType === QUESTION_TYPES.matrix &&
      !isOnEndEdge(currentIndex)
    ) {
      return undefined;
    }

    const { allSkip } = computeSkipValues({
      ...scenarioProps,
      currentIndex,
      currentQuestion,
      questions,
    });

    const filteredQuestions = questions.filter(
      ({ answers, id, questionType }: any) =>
        allSkip.indexOf(id) === -1 &&
        questionHasConsistentAnswers(answers, questionType)
    );
    const idx = filteredQuestions.findIndex(q => q.id === currentQuestion.id);
    const newCurrent = filteredQuestions[idx + 1];

    if (newCurrent) {
      return newCurrent;
    }
    return undefined;
  };

  return { getNextQuestion, nextQuestion };
};
