import ELEMENT from 'constant/element';

import { CampaignVI, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { EmailStep } from './emailStep';
import { BasicQuestion } from './questions/basicQuestion';
import { MatrixQuestion } from './questions/matrixQuestion/MatrixQuestion';
import NpsQuestion from './questions/npsQuestion';
import RadarQuestion from './questions/radarQuestion';
import { VerbatimQuestion } from './questions/verbatimQuestion';
import { WordPairQuestion } from './questions/wordPairQuestion/WordPairQuestion';
import { ThankYouStep } from './thankYouStep/ThankYouStep';

type GetStepsProps = {
  campaign: CampaignVI;
  currentDimensionIndexes: { [key: string]: number };
  currentElement: string;
  currentQuestion: QuestionVI;
  handleEmailChange: any;
  hasError: boolean;
  questionAnswers: any;
  participant: any;
  questions: QuestionVI[];
  selectAnswer: any;
  selectMatrix: any;
  selectMultiLevel: any;
  selectNPS: any;
  selectRank: any;
  selectVerbatim: (answers: string[]) => void;
  submitVote: () => void;
};

export const getSteps = ({
  campaign,
  currentDimensionIndexes,
  currentElement,
  currentQuestion,
  handleEmailChange,
  hasError,
  questionAnswers,
  participant,
  questions,
  selectAnswer,
  selectMatrix,
  selectMultiLevel,
  selectNPS,
  selectRank,
  selectVerbatim,
  submitVote,
}: GetStepsProps) => {
  const { emailScreen } = campaign;

  const getQuestionProps = (question: any) => ({
    ...question,
    active:
      currentElement === ELEMENT.question && currentQuestion.id === question.id,
    currentAnswer: questionAnswers[question.id],
    key: question.id,
  });

  return [
    ...questions.map((question: any, index: number) => {
      const { questionType } = question;

      switch (questionType) {
        case QUESTION_TYPES.verbatim:
          return (
            <VerbatimQuestion
              {...getQuestionProps(question)}
              selectVerbatim={selectVerbatim}
              submitVote={submitVote}
            />
          );
        case QUESTION_TYPES.nps:
          return (
            <NpsQuestion
              {...getQuestionProps(question)}
              selectNPS={selectNPS}
            />
          );
        case QUESTION_TYPES.ranking:
        case QUESTION_TYPES.multi:
        case QUESTION_TYPES.simple:
          return (
            <BasicQuestion
              {...getQuestionProps(question)}
              isRanking={questionType === QUESTION_TYPES.ranking}
              hasError={hasError}
              selectAnswer={
                questionType === QUESTION_TYPES.ranking
                  ? selectRank
                  : selectAnswer
              }
            />
          );
        case QUESTION_TYPES.radar:
          return (
            <RadarQuestion
              {...getQuestionProps(question)}
              selectAnswer={selectMultiLevel}
            />
          );
        case QUESTION_TYPES.wordPairs:
          return (
            <WordPairQuestion
              {...getQuestionProps(question)}
              selectAnswer={selectMultiLevel}
            />
          );
        case QUESTION_TYPES.matrix:
          return (
            <MatrixQuestion
              {...getQuestionProps(question)}
              currentDimensionIndex={currentDimensionIndexes[index]}
              selectAnswer={selectMatrix}
            />
          );
        default:
          return null;
      }
    }),
    emailScreen && (
      <EmailStep
        emailScreen={emailScreen}
        handleEmailChange={handleEmailChange}
        key="email-step"
        participant={participant}
      />
    ),

    <ThankYouStep
      active={currentElement === ELEMENT.message}
      key="thank-you-step"
    />,
  ].filter(step => step);
};
