import React, { useCallback, useContext } from 'react';

import { AD_WRAPPER_EVENTS } from 'constant/adWrapperEvents';

import { useAdWrapper } from 'hooks/useAdWrapper';

import { ParametersContext } from 'contexts/parametersContext';

import { CloseButton } from 'components/shared/buttons/closeButton/CloseButton';

import './errorPage.scss';

function ErrorPage() {
  const { interactive: interactiveAdWrapperDetected } =
    useContext(ParametersContext);

  const { notifyAdWrapper } = useAdWrapper();

  const handleClick = useCallback(() => {
    notifyAdWrapper(AD_WRAPPER_EVENTS.closeRequested);
  }, [notifyAdWrapper]);

  return (
    <div id="error-boundary">
      {interactiveAdWrapperDetected && <CloseButton onClick={handleClick} />}
    </div>
  );
}

const MemoErrorPage = React.memo(ErrorPage);

export { MemoErrorPage as ErrorPage };
