import { useCallback, useEffect, useState } from 'react';

import ELEMENT from 'constant/element';

import { CampaignVI, ParticipantVI } from 'types/campaign';

import { useAdWrapper } from 'hooks/useAdWrapper';

import { initializeQuestionAnswers } from './hooks/utils/initializeQuestionAnswers';

import { DataFunction } from 'components/api/useApi/types';

import { navigationFunction } from './hooks/navigationFunction';
import {
  submitParticipant as sbp,
  submitVote as sbv,
} from './hooks/submitFunction';
import { thankScreenCallbacks } from './hooks/thanksScreenFunction';
import { getSteps } from './steps';

import { useAutoSwitch } from './hooks/useAutoSwitch';
import { useDimensionsNavigation } from './hooks/useDimensionsNavigation';
import { useNavigationBehaviour } from './hooks/useNavigationBehaviour';
import { useNextQuestion } from './hooks/useNextQuestion';
import { useNextReady } from './hooks/useNextReady';
import { useSelectAnswers } from './hooks/useSelectAnswers';
import { useVerbatimKeyboardOpening } from './hooks/useVerbatimKeyboardOpening';
import { useInconsistencies } from './steps/questions/hooks/useInconsistencies';

export const useSurveyScreen = (props: {
  campaign: CampaignVI;
  sendData: DataFunction;
  skipData: DataFunction;
}) => {
  const { campaign, sendData, skipData } = props;
  const { emailScreen, questions, participantId } = campaign;
  const lastQuestion = questions[questions.length - 1];

  const [currentElement, setCurrentElement] = useState(
    questions.length > 0 ? ELEMENT.question : ELEMENT.message
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0] || {});
  const [prevReady, setPrevReady] = useState(false);
  const [allSkipQuestions, setAllSkipQuestions] = useState<number[]>([]);
  const [hasError, setHasError] = useState(false);
  const [participant, setParticipant] = useState<ParticipantVI>({
    email: '',
    emailValid: false,
    id: participantId,
  });

  const [skipQuestions, setSkipQuestions] = useState(
    questions.reduce((acc: { [key: string]: [] }, question: any) => {
      acc[question.id] = [];
      return acc;
    }, {})
  );

  const [questionAnswers, setQuestionAnswers] = useState<{
    [questionId: string]: any;
  }>(initializeQuestionAnswers(questions));

  const filteredAnswers = useInconsistencies({
    questionAnswers,
    questions,
  });

  const questionsWithFilteredAnswers = questions.map((question, index) => ({
    ...question,
    answers: filteredAnswers[index],
  }));

  const {
    currentDimensionIndexes,
    goToNextDimension,
    goToPreviousDimension,
    isOnEndEdge,
    isOnStartEdge,
  } = useDimensionsNavigation(questionsWithFilteredAnswers);

  const nextReady = useNextReady({
    currentDimensionIndexes,
    currentElement,
    currentIndex,
    currentQuestion,
    participant,
    records: questionAnswers,
  });

  const { notifyAdWrapper } = useAdWrapper();

  const { getNextQuestion: gnQuestion, nextQuestion: nQuestion } =
    useNextQuestion();

  const nextQuestion = useCallback(
    (newQuestionAnswers = undefined) =>
      nQuestion({
        currentIndex,
        currentQuestion,
        emailScreen,
        goToNextDimension,
        isOnEndEdge,
        questionAnswers,
        questions: questionsWithFilteredAnswers,
        scenarioProps: {
          questionAnswers: newQuestionAnswers || questionAnswers,
          setAllSkipQuestions,
          setQuestionAnswers,
          setSkipQuestions,
          skipQuestions,
        },
        setCurrentElement,
        setCurrentIndex,
        setCurrentQuestion,
        setPrevReady,
        skipData,
      }),
    [
      currentIndex,
      currentQuestion,
      emailScreen,
      goToNextDimension,
      isOnEndEdge,
      nQuestion,
      questionAnswers,
      questionsWithFilteredAnswers,
      skipData,
      skipQuestions,
    ]
  );

  const getNextQuestion = useCallback(
    () =>
      gnQuestion({
        currentIndex,
        currentQuestion,
        isOnEndEdge,
        questions: questionsWithFilteredAnswers,
        scenarioProps: {
          questionAnswers,
          setAllSkipQuestions,
          setQuestionAnswers,
          setSkipQuestions,
          skipQuestions,
        },
      }),
    [
      currentIndex,
      currentQuestion,
      gnQuestion,
      isOnEndEdge,
      questionAnswers,
      questionsWithFilteredAnswers,
      skipQuestions,
    ]
  );

  useVerbatimKeyboardOpening({
    currentQuestion,
    getNextQuestion,
    questionAnswers,
  });

  const submitVote = useCallback(() => {
    setTimeout(
      () =>
        sbv({
          currentQuestion,
          lastQuestion,
          nextQuestion,
          notifyAdWrapper,
          questionAnswers,
          sendData,
        }),
      100
    );
  }, [
    currentQuestion,
    lastQuestion,
    nextQuestion,
    notifyAdWrapper,
    questionAnswers,
    sendData,
  ]);

  const submitParticipant = () => {
    sbp({
      notifyAdWrapper,
      participant,
      questions: questionsWithFilteredAnswers,
      sendData,
      setCurrentElement,
      setCurrentIndex,
      setParticipant,
    });
  };

  useAutoSwitch({
    currentDimensionIndexes,
    currentIndex,
    currentQuestion,
    goToNextDimension,
    isOnEndEdge,
    questionAnswers,
    submitVote,
  });

  const {
    selectAnswer,
    selectMatrix,
    selectMultiLevel,
    selectNPS,
    selectRank,
    selectVerbatim,
  } = useSelectAnswers({
    currentQuestion,
    questionAnswers,
    questions,
    setHasError,
    setQuestionAnswers,
  });

  const { next, prev } = navigationFunction({
    allSkipQuestions,
    currentElement,
    currentIndex,
    currentQuestion,
    goToPreviousDimension,
    isOnStartEdge,
    nextQuestion,
    questionAnswers,
    questions: questionsWithFilteredAnswers,
    setCurrentElement,
    setCurrentIndex,
    setCurrentQuestion,
    setHasError,
    setPrevReady,
    submitParticipant,
    submitVote,
  });

  const handleEmailChange = (event: any) => {
    const email = event.target.value.trim();
    const isEmailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );

    setParticipant(state => ({
      ...state,
      email,
      emailValid: isEmailValid,
    }));
  };

  const steps = getSteps({
    campaign,
    currentDimensionIndexes,
    currentElement,
    currentQuestion,
    handleEmailChange,
    hasError,
    participant,
    questionAnswers,
    questions: questionsWithFilteredAnswers,
    selectAnswer,
    selectMatrix,
    selectMultiLevel,
    selectNPS,
    selectRank,
    selectVerbatim,
    submitVote,
  });

  const { isParticipantElement, nextDisabled, nextHidden } =
    useNavigationBehaviour({
      currentDimensionIndexes,
      currentElement,
      currentIndex,
      currentQuestion,
      nextReady,
      questionAnswers,
      stepsLength: steps.length,
    });

  useEffect(() => {
    if (currentElement === ELEMENT.message) {
      thankScreenCallbacks(campaign);
    }
  }, [campaign, currentElement]);

  return {
    currentIndex,
    currentQuestion,
    isParticipantElement,
    next,
    nextDisabled,
    nextHidden,
    prev,
    prevReady,
    steps,
  };
};
