import { CampaignVI } from 'types/campaign';

import { useTranslations } from 'hooks/useTranslations';

import { HappydemicsLogo } from '../../shared/happydemicsLogo/HappydemicsLogo';
import introductionIllustration from './img/introduction_illustration.svg';

import './introScreen.scss';

type IntroScreenProps = {
  callback: () => void;
  campaign: CampaignVI;
};

export function IntroScreen({ callback, campaign }: IntroScreenProps) {
  const { t } = useTranslations();

  const { cookiesText, introScreen = {} } = campaign;
  const { bodyText = '', buttonLabel = 'Go' } = introScreen;

  return (
    <div className="intro-screen cover">
      <div className="stack-small center">
        <p>{t('intro_screen_header')}</p>
        <div>
          <HappydemicsLogo />
        </div>
      </div>
      <div className="centered center">
        <div className="stack">
          <div>
            <img
              alt="Two people answering an Happydemics survey"
              src={introductionIllustration}
            />
          </div>
          <p>{bodyText}</p>
          <div className="stack-small">
            <button onClick={callback} type="button">
              {buttonLabel}
            </button>
            <p className="small">{cookiesText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
