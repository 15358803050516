import { memo } from 'react';

import { Dimension } from 'types/dimension';

import { Counter } from './counter/Counter';
import { ImageItem } from './imageItem/ImageItem';

import styles from './Item.module.scss';

type ItemProps = {
  currentDimension: Dimension;
  index: number;
  dimensionsCount: number;
};

const Item = ({
  currentDimension,
  dimensionsCount,
  index,
}: ItemProps): JSX.Element => {
  const { body, image } = currentDimension;

  return (
    <>
      {image ? (
        <ImageItem
          body={body}
          dimensionsCount={dimensionsCount}
          image={image}
          index={index}
        />
      ) : (
        <div className={styles.item} dir="auto">
          {body}
          <Counter dimensionsCount={dimensionsCount} index={index} />
        </div>
      )}
    </>
  );
};

const ItemMemo = memo(Item);

export { ItemMemo as Item };
