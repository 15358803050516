import { useState } from 'react';

import { RecruitmentScreenProps } from './recruitmentScreen.type';

import { HappydemicsLogo } from '../../shared/happydemicsLogo/HappydemicsLogo';
import { AnswerButton } from './answerButton';
import { Illustration } from './illustration';
import { LastScreen } from './lastScreen';

import './recruitmentScreen.scss';

export const RecruitmentScreen = ({
  interactive,
  recruitmentScreen,
  redirectionUrl,
}: RecruitmentScreenProps) => {
  const [lastScreen, setLastScreen] = useState(false);

  const {
    image,
    introduction,
    legalInformation,
    negativeAnswer,
    positiveAnswer,
    question,
  } = recruitmentScreen;

  if (lastScreen) {
    return <LastScreen interactive={interactive} />;
  }

  return (
    <div className="recruitment-screen">
      <div className="content">
        <div>
          <HappydemicsLogo />
        </div>

        <Illustration image={image} />
        <div className="introduction">{introduction}</div>
        <div className="question-container">
          <div className="question-box">
            <span className="question-text">{question}</span>
          </div>
          <AnswerButton
            displayLastScreen={() => setLastScreen(true)}
            interactive={interactive}
            negativeAnswer={negativeAnswer}
            positiveAnswer={positiveAnswer}
            redirectionUrl={redirectionUrl}
          />
        </div>
        <div className="legal-information">{legalInformation}</div>
      </div>
    </div>
  );
};
