import { useEffect, useState } from 'react';

import _ from 'lodash';

import ELEMENT from 'constant/element';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

type NavigationBehaviourProps = {
  currentDimensionIndexes: { [key: string]: number };
  currentElement: string;
  currentIndex: number;
  currentQuestion: QuestionVI;
  nextReady: boolean;
  questionAnswers: {
    [key: string]: any;
  };
  stepsLength: number;
};

/**
 * This hook manages the display of the next button with 2 states : disabled or hidden.
 * After the submitVote(), the button is flag to nextReady and the button next is displayed.
 * For the autoswitched question (simple, nps, matrix ...), the button is displayed for a few milliseconds (flash effect)
 * and we don't want this button to be displayed.
 * So, we added this timeout that will show the next button after 100ms, time during which we will switch the question
 * and have a new state for the next button.
 */

export const useNavigationBehaviour = ({
  currentDimensionIndexes,
  currentElement,
  currentIndex,
  currentQuestion = {} as QuestionVI,
  nextReady,
  questionAnswers,
  stepsLength,
}: NavigationBehaviourProps) => {
  const isQuestionElement = currentElement === ELEMENT.question;
  const isParticipantElement = currentElement === ELEMENT.participant;
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextHidden, setNextHidden] = useState(true);

  const isOnLastStep = currentIndex === stepsLength - 1;

  const { dimensions = [], id, questionType } = currentQuestion;

  useEffect(() => {
    const nextDisabledHandler = setTimeout(
      () =>
        setNextDisabled(() => {
          if (
            questionType !== QUESTION_TYPES.matrix ||
            currentElement !== ELEMENT.question
          ) {
            return !nextReady;
          }

          const matrixAnswers = questionAnswers[id];

          const dimensionIndex = currentDimensionIndexes[currentIndex];
          if (_.isNil(dimensionIndex)) return true;

          const { id: dimensionId } = dimensions[dimensionIndex];
          const dimensionAnswer = matrixAnswers[dimensionId];

          return _.isEmpty(dimensionAnswer);
        }),
      100
    );
    return () => {
      clearTimeout(nextDisabledHandler);
    };
  }, [
    currentDimensionIndexes,
    currentElement,
    currentIndex,
    dimensions,
    id,
    nextReady,
    questionAnswers,
    questionType,
  ]);

  useEffect(() => {
    const nextHiddenHandler = setTimeout(
      () =>
        setNextHidden(
          () =>
            (nextDisabled &&
              [QUESTION_TYPES.simple, QUESTION_TYPES.matrix].includes(
                questionType as QUESTION_TYPES
              )) ||
            isOnLastStep
        ),
      100
    );
    return () => {
      clearTimeout(nextHiddenHandler);
    };
  }, [isOnLastStep, nextDisabled, questionType]);

  return {
    isOnLastStep,
    isParticipantElement,
    isQuestionElement,
    nextDisabled,
    nextHidden,
  };
};
