import { Dispatch, SetStateAction } from 'react';

import ELEMENT from 'constant/element';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { questionHasConsistentAnswers } from './utils/answerAnalysis';

type NavigationFunctionProps = {
  allSkipQuestions: number[];
  currentElement: string;
  currentIndex: number;
  currentQuestion: QuestionVI;
  goToPreviousDimension: (questionIndex: number) => void;
  isOnStartEdge: (questionIndex: number) => boolean;
  nextQuestion: (newQuestionAnswers?: any) => void;
  questionAnswers: {
    [questionId: string]: any;
  };
  questions: QuestionVI[];
  setCurrentElement: Dispatch<SetStateAction<string>>;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  setCurrentQuestion: Dispatch<SetStateAction<QuestionVI>>;
  setHasError: Dispatch<SetStateAction<boolean>>;
  setPrevReady: Dispatch<SetStateAction<boolean>>;
  submitParticipant: () => void;
  submitVote: () => void;
};

export const navigationFunction = ({
  allSkipQuestions,
  currentElement,
  currentIndex,
  currentQuestion,
  goToPreviousDimension,
  isOnStartEdge,
  nextQuestion,
  questionAnswers,
  questions,
  setCurrentElement,
  setCurrentIndex,
  setCurrentQuestion,
  setHasError,
  setPrevReady,
  submitParticipant,
  submitVote,
}: NavigationFunctionProps) => {
  const next = () => {
    if (currentElement === ELEMENT.question) {
      const answers = questionAnswers[currentQuestion.id];
      const valid =
        ([QUESTION_TYPES.radar, QUESTION_TYPES.wordPairs].includes(
          currentQuestion.questionType as QUESTION_TYPES
        ) &&
          Object.values(currentQuestion.answers).findIndex(
            level => level === undefined
          ) === -1) ||
        (answers.length === 1 &&
          currentQuestion.questionType !== QUESTION_TYPES.verbatim &&
          currentQuestion.answers?.find(a => a.id === answers[0])?.none) ||
        (currentQuestion.minAnswers
          ? answers.length >= currentQuestion.minAnswers
          : answers.length > 0);

      if (currentQuestion.questionType === QUESTION_TYPES.matrix) {
        if (
          Object.values(answers).findIndex(
            (level: any) => level.length === 0
          ) === -1
        ) {
          setHasError(false);
          submitVote();
          return;
        }
        nextQuestion();
      }

      if (currentQuestion.questionType === QUESTION_TYPES.nps || valid) {
        setHasError(false);
        submitVote();
      } else {
        setHasError(true);
        return false;
      }
    } else {
      submitParticipant();
    }
  };

  const prev = () => {
    if (currentElement === ELEMENT.participant) {
      setCurrentElement(ELEMENT.question);
      setCurrentIndex(questions.indexOf(currentQuestion));
    } else if (
      currentQuestion.questionType === QUESTION_TYPES.matrix &&
      !isOnStartEdge(currentIndex)
    ) {
      goToPreviousDimension(currentIndex);
    } else {
      const filteredQuestions = questions.filter(
        ({ answers, id, questionType }) =>
          allSkipQuestions.indexOf(id) === -1 &&
          questionHasConsistentAnswers(answers, questionType)
      );
      const idx = filteredQuestions.findIndex(q => q.id === currentQuestion.id);
      const newCurrent = filteredQuestions[idx - 1];
      if (newCurrent) {
        setCurrentIndex(questions.indexOf(newCurrent));
        setCurrentQuestion(newCurrent);
        setPrevReady(currentIndex - 1 > 0);
      }
    }
  };

  return {
    next,
    prev,
  };
};
