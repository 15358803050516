import { useState } from 'react';

import cn from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/img/icons/cross.svg';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import happydemicsLogo from './logo_hdm_white.svg';

import styles from './cookiesBanner.module.scss';

type CookiesBannerProps = {
  forceHide: boolean;
  cookiesText: string;
  introScreen: boolean;
};

export function CookiesBanner({
  forceHide,
  cookiesText,
  introScreen,
}: CookiesBannerProps) {
  const [displayed, setDisplayed] = useState(true);

  const { isLandscape } = useScreenOrientation();

  if (!displayed || introScreen || forceHide) return null;

  return (
    <hdm-box
      class={cn(styles.cookiesBanner, {
        [styles.isLandscape]: isLandscape,
      })}
    >
      <div className={styles.cookiesContent}>
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => setDisplayed(false)}
        >
          <CrossIcon />
        </button>
        <hdm-stack class={styles.cookiesStack}>
          <img
            className={styles.hdmLogo}
            alt="Happydemics"
            src={happydemicsLogo}
          />
          <span className={styles.text}>{cookiesText}</span>
        </hdm-stack>
      </div>
    </hdm-box>
  );
}
