import { useModal } from 'hooks/useModal';
import { useTranslations } from 'hooks/useTranslations';

import { GdprView } from './gdprView';

import styles from './gdprButton.module.scss';

export function GdprButton() {
  const { t } = useTranslations();

  const { closeModal, displayedModal, openModal } = useModal();

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className={styles.gdprButtonSection}
      >
        {t('gdpr_privacy_policy_link')}
      </button>

      {displayedModal && <GdprView close={closeModal} />}
    </>
  );
}
