import { useCallback, useEffect, useState } from 'react';

import { AD_WRAPPER_EVENTS } from 'constant/adWrapperEvents';

import { useAdWrapper } from 'hooks/useAdWrapper';

export const useRootNavigation = ({
  introScreen,
  preview,
  sendData,
}: {
  introScreen: {} | undefined;
  preview: boolean;
  sendData: any;
}) => {
  const [step, setStep] = useState(
    introScreen && !preview ? 'introduction' : 'question'
  );

  const introCallback = useCallback(() => {
    setStep('question');
    sendData('intro_screen_confirm', { path: 'events/intro_screen_confirm' });
  }, [sendData]);

  const { notifyAdWrapper } = useAdWrapper();
  useEffect(() => {
    notifyAdWrapper(AD_WRAPPER_EVENTS.startReached);
  }, [notifyAdWrapper]);

  return { introCallback, step };
};
