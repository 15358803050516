import { Audio } from './audio';
import { Image } from './image';
import { Video } from './video';

import styles from './media.module.scss';

export type MediaProps = {
  active: boolean;
  audio?: string;
  audioType?: string;
  image?: string;
  video?: string;
};

const MediaSwitcher = ({
  active,
  audio,
  audioType,
  image,
  video,
}: Omit<MediaProps, 'hasMedia'>) => {
  if (audio) {
    return <Audio active={active} audio={audio} audioType={audioType} />;
  }

  if (video) {
    return <Video active={active} video={video} />;
  }

  if (image) {
    return <Image active={active} image={image} />;
  }

  return null;
};

export function Media({ active, audio, audioType, image, video }: MediaProps) {
  const hasMedia = audio || image || video;

  if (!audio && !hasMedia && !image && !video) return null;
  return (
    <div className={styles.mediaQuestion}>
      <MediaSwitcher
        active={active}
        audioType={audioType}
        image={image}
        video={video}
        audio={audio}
      />
    </div>
  );
}
