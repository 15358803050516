import styles from './progressBar.module.scss';

type ProgressBarProps = {
  progress: number;
};

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <div className={styles.progressBar}>
      <div className={styles.bar} style={{ width: `${progress * 100}%` }} />
    </div>
  );
}
