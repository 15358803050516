import { memo } from 'react';

import cn from 'classnames';

import { useAssetUrl } from 'hooks/useAssetUrl';
import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { FullscreenImage } from './fullscreenImage';
import maximizeIcon from './maximize.svg';

import { useFullscreenControl } from './useFullscreenControl';

import styles from './image.module.scss';

type ImageProps = {
  active: Boolean;
  image: string;
};

function Image({ active, image }: ImageProps) {
  const { close, fullscreenMode, hiding, show } = useFullscreenControl();
  const { isLandscape } = useScreenOrientation();

  const src = useAssetUrl(image);

  return (
    <div
      className={cn(styles.image, {
        [styles.isLandscape]: isLandscape,
      })}
    >
      <div className={styles.container} onClick={show}>
        <button className={styles.maximize} type="button">
          <img alt="Maximize" src={maximizeIcon} height={15} width={15} />
        </button>
        <div className={styles.imageButton}>
          {active && <img src={src} alt="Media" />}
        </div>
        {active && fullscreenMode && (
          <FullscreenImage close={close} hiding={hiding} src={src} />
        )}
      </div>
    </div>
  );
}

const MemoImage = memo(Image);

export { MemoImage as Image };
