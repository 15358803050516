import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { TextAnswerItem } from './textAnswerItem/TextAnswerItem';

import styles from './textAnswerItems.module.scss';

type TextAnswerItemsProps = {
  answers: AnswerVI[];
  className?: string;
  isRanking?: boolean;
  selectAnswer: (answerId: any) => void;
  selectedAnswer: any[];
};

export function TextAnswerItems({
  answers,
  className,
  isRanking,
  selectAnswer,
  selectedAnswer,
}: TextAnswerItemsProps) {
  const { isLandscape } = useScreenOrientation();

  return (
    <hdm-stack
      class={cn(
        styles.textAnswerItems,
        {
          [styles.isLandscape]: isLandscape,
        },
        className
      )}
    >
      {answers.map((answer: any) => (
        <TextAnswerItem
          key={answer.id}
          answer={answer}
          isRanking={isRanking}
          selectAnswer={selectAnswer}
          selectedAnswer={selectedAnswer}
        />
      ))}
    </hdm-stack>
  );
}
