import { useContext } from 'react';

import { ParametersContext } from 'contexts/parametersContext';

export const useAssetUrl = (path: string): string => {
  const { assetsUrl } = useContext(ParametersContext) || {};

  if (!path) {
    return '';
  }

  try {
    // eslint-disable-next-line no-new
    new URL(path);
  } catch (_) {
    const url = new URL(path, assetsUrl);

    return url.toString();
  }

  return path;
};
