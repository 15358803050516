import { RefObject, useEffect } from 'react';

export const useScrollToTop = (
  hide: boolean,
  selectedAnswer: any[],
  stepRef: RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    if (selectedAnswer.length === 0) {
      const { current: step } = stepRef;
      step?.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide]);
};
