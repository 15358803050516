import { useCallback, useContext, useEffect } from 'react';

import { AD_WRAPPER_EVENTS } from 'constant/adWrapperEvents';

import { useAdWrapper } from 'hooks/useAdWrapper';

import { ParametersContext } from 'contexts/parametersContext';

export const useThankYouStep = (active: boolean) => {
  const { interactive: interactiveAdWrapperDetected } =
    useContext(ParametersContext);
  const { notifyAdWrapper } = useAdWrapper();

  const closeInterface = useCallback(() => {
    notifyAdWrapper(AD_WRAPPER_EVENTS.closeRequested);
  }, [notifyAdWrapper]);

  useEffect(() => {
    if (active) {
      notifyAdWrapper(AD_WRAPPER_EVENTS.endReached);
    }
  }, [active, notifyAdWrapper]);

  return {
    closeInterface,
    displayCloseButton: interactiveAdWrapperDetected,
  };
};
