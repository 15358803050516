import { useCallback, useEffect } from 'react';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { useTranslations } from 'hooks/useTranslations';

/**
 * Bypass disabled input autofocus on Apple devices
 */

/**
 * In order to open the verbatim keyboard automatically when we are on a verbatim question, we have to listen
 * the click event. Two cases :
 * - It's not an autoswitched question (question with multiple answers) : to change the question, we have to click
 * on a footer button : handleKeyboardOpeningOnFooterButtons
 * - It's an autoswitched question : we have to listen the click event on question answer just before the verbatim question :
 * handleKeyboardOpeningOnSurveyButtons
 */

export const useVerbatimKeyboardOpening = ({
  currentQuestion,
  getNextQuestion,
  questionAnswers,
}: {
  currentQuestion: QuestionVI;
  getNextQuestion: () => any;
  questionAnswers: {};
}) => {
  const { t } = useTranslations();
  const { questionType, multipleAnswers } = currentQuestion;

  const questionAutoSwitched =
    (questionType === QUESTION_TYPES.simple && !multipleAnswers) ||
    (questionType === QUESTION_TYPES.matrix && !multipleAnswers);

  /**
   * This function checks if the next question if a verbatim question.
   * If it is, for Apple devices, the focus is forced and the keyboard is opened.
   */

  const handleVerbatimKeyboardOpening = useCallback(() => {
    const newCurrent = getNextQuestion();

    if (!newCurrent) return;

    const { id, questionType: newCurrentQuestionType } = newCurrent;

    if (newCurrentQuestionType !== QUESTION_TYPES.verbatim) return;

    const answers = (questionAnswers as any)[id];

    const prefilledAnswers = [
      t('verbatim_idk_text'),
      t('verbatim_no_time_text'),
    ];
    if (prefilledAnswers.includes(answers[0])) return;

    const tempInput = document.getElementById('temp-verbatim-input');

    tempInput?.focus();
  }, [getNextQuestion, questionAnswers, t]);

  // We trigger click event on all buttons of autoswitched question

  const handleKeyboardOpeningOnSurveyButtons = useCallback(() => {
    const surveyBody = document.getElementById('survey-screen-body');

    if (!surveyBody) return;

    const surveyButtons = surveyBody.getElementsByTagName('button');
    for (let i = 0; i < surveyButtons.length; i += 1) {
      surveyButtons[i].addEventListener('click', handleVerbatimKeyboardOpening);
    }

    return () => {
      for (let i = 0; i < surveyButtons.length; i += 1) {
        surveyButtons[i].removeEventListener(
          'click',
          handleVerbatimKeyboardOpening
        );
      }
    };
  }, [handleVerbatimKeyboardOpening]);

  useEffect(() => {
    if (!questionAutoSwitched) return;
    return handleKeyboardOpeningOnSurveyButtons();
  }, [
    currentQuestion,
    handleKeyboardOpeningOnSurveyButtons,
    questionAutoSwitched,
    questionType,
  ]);

  // We trigger click event on footer buttons

  const handleKeyboardOpeningOnFooterButtons = useCallback(() => {
    const footer = document.getElementById('footer-buttons');
    if (!footer) return;

    const footerButtons = footer.getElementsByTagName('button');
    for (let i = 0; i < footerButtons.length; i += 1) {
      const footerButton = footerButtons[i];
      if (footerButton.id === 'button-navigation-next') {
        footerButton.addEventListener('click', handleVerbatimKeyboardOpening);
      }
    }

    return () => {
      for (let i = 0; i < footerButtons.length; i += 1) {
        footerButtons[i].removeEventListener(
          'click',
          handleVerbatimKeyboardOpening
        );
      }
    };
  }, [handleVerbatimKeyboardOpening]);

  useEffect(() => {
    return handleKeyboardOpeningOnFooterButtons();
  }, [handleKeyboardOpeningOnFooterButtons]);

  return handleVerbatimKeyboardOpening;
};
