import { useCallback, useEffect, useState } from 'react';

import { useTranslations } from 'hooks/useTranslations';

export const useVerbatimQuestion = ({
  multipleVerbatim,
  selectVerbatim,
}: {
  multipleVerbatim: boolean;
  selectVerbatim: (answers: string[]) => void;
}) => {
  const { t } = useTranslations();

  const [verbatims, setVerbatims] = useState(['']);
  const [focused, setFocused] = useState(0);

  const [defaultAnswer, setDefaultAnswer] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (verbatims[0] !== '') {
      setDefaultAnswer(undefined);
      selectVerbatim(verbatims.filter(verbatim => verbatim !== ''));
    } else {
      selectVerbatim([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verbatims]);

  useEffect(() => {
    if (defaultAnswer !== undefined) {
      setVerbatims(['']);
      selectVerbatim([t(defaultAnswer)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAnswer, t]);

  const dropVerbatim = useCallback(
    position => {
      setVerbatims([
        ...verbatims.slice(0, position),
        ...verbatims.slice(position + 1),
      ]);
    },
    [verbatims]
  );

  const updateVerbatimAndPushNewEmpty = useCallback(
    (text, position) => {
      setVerbatims([...verbatims.slice(0, position), text, '']);
    },
    [verbatims]
  );

  const updateVerbatim = useCallback(
    (text, position) => {
      setVerbatims([
        ...verbatims.slice(0, position),
        text,
        ...verbatims.slice(position + 1),
      ]);
    },
    [verbatims]
  );

  const handleVerbatimChange = useCallback(
    (text, position = 0) => {
      if (text === '' && verbatims.length > 1) {
        dropVerbatim(position);
      } else if (
        multipleVerbatim &&
        position === verbatims.length - 1 &&
        text !== ''
      ) {
        updateVerbatimAndPushNewEmpty(text, position);
      } else {
        updateVerbatim(text, position);
      }
    },
    [
      dropVerbatim,
      multipleVerbatim,
      updateVerbatim,
      updateVerbatimAndPushNewEmpty,
      verbatims.length,
    ]
  );

  return {
    defaultAnswer,
    focused,
    handleVerbatimChange,
    setDefaultAnswer,
    setFocused,
    verbatims,
  };
};
