import { useCallback, useContext } from 'react';

import { Translations } from 'types/translations';

import { TranslationsContext } from 'contexts/translationsContext';

export const useTranslations = () => {
  const translations = useContext<Translations>(TranslationsContext);

  const t = useCallback(
    (key: string): string => translations[key] || '',
    [translations]
  );

  return { t };
};
