import { Dispatch, SetStateAction, useCallback } from 'react';

import _ from 'lodash';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { clearInconsistencies } from './utils/clearInconsistencies';

export const useSelectAnswers = ({
  currentQuestion,
  questionAnswers,
  questions,
  setHasError,
  setQuestionAnswers,
}: {
  currentQuestion: QuestionVI;
  questionAnswers: { [key: string]: any };
  questions: QuestionVI[];
  setHasError: Dispatch<SetStateAction<boolean>>;
  setQuestionAnswers: Dispatch<
    SetStateAction<{
      [questionId: string]: any;
    }>
  >;
}) => {
  const isAnswerNone = (answerId: number) =>
    !!currentQuestion.answers.find(answer => answer.id === answerId)?.none;

  const shouldResetSelection = (answerId: number, currentAnswers: any[]) => {
    const answerIsNone = isAnswerNone(answerId);
    const noneWasSelected =
      currentAnswers.length === 1 && isAnswerNone(currentAnswers[0]);

    return answerIsNone || noneWasSelected;
  };

  const selectAnswer = (id: number) => {
    // health check -> make sure id belongs to currentQuestion.answers
    if (!currentQuestion.answers.find((answer: any) => answer.id === id)) {
      return false;
    }

    const answers = questionAnswers[currentQuestion.id];
    let newAnswers: number[] = [];
    const idx = answers.indexOf(id);
    if (currentQuestion.questionType === QUESTION_TYPES.multi) {
      if (idx !== -1) {
        newAnswers = [...answers.slice(0, idx), ...answers.slice(idx + 1)];
      } else if (shouldResetSelection(id, answers)) {
        newAnswers = [id];
      } else {
        // check for max answers
        if (
          currentQuestion.maxAnswers &&
          currentQuestion.maxAnswers === answers.length
        ) {
          setHasError(true);
          return false;
        }
        newAnswers = [...answers, id];
      }
    } else {
      newAnswers = [id];
    }

    const newRecords = clearInconsistencies({
      newRecord: newAnswers,
      originQuestionId: currentQuestion.id,
      questions,
      records: questionAnswers,
    });

    setQuestionAnswers(newRecords);
  };

  const selectNPS = (val: any) => {
    setQuestionAnswers((state: any[]) => ({
      ...state,
      [currentQuestion.id]: val,
    }));
  };

  const selectMatrix = (dimensionId: number, answerId: number) => {
    const hasMultipleAnswer = currentQuestion.multipleAnswers;

    setQuestionAnswers((state: any[]) => {
      const dimensions = state[currentQuestion.id];

      const newDimensions = {
        ...dimensions,
      };

      if (hasMultipleAnswer) {
        const indexOfAnswerId = dimensions[dimensionId].indexOf(answerId);

        let newAnswers = [...dimensions[dimensionId]];

        if (indexOfAnswerId >= 0) {
          newAnswers.splice(indexOfAnswerId, 1);
        } else if (shouldResetSelection(answerId, newAnswers)) {
          newAnswers = [answerId];
        } else {
          newAnswers.push(answerId);
        }
        newDimensions[dimensionId] = newAnswers;
      } else {
        newDimensions[dimensionId] = [answerId];
      }

      return clearInconsistencies({
        newRecord: newDimensions,
        originQuestionId: currentQuestion.id,
        questions,
        records: state,
      });
    });
  };

  const selectMultiLevel = (id: number, level: any) => {
    const answers = questionAnswers[currentQuestion.id];

    const newAnswers = {
      ...answers,
      [id]: level,
    };

    setQuestionAnswers((state: any[]) =>
      clearInconsistencies({
        newRecord: newAnswers,
        originQuestionId: currentQuestion.id,
        questions,
        records: state,
      })
    );
  };

  const selectRank = (id: number) => {
    const answers = questionAnswers[currentQuestion.id];
    // health check -> make sure id belongs to currentQuestion.answers
    if (!currentQuestion.answers.find((answer: any) => answer.id === id)) {
      return false;
    }
    let newAnswers = [];
    const idx = answers.indexOf(id);
    if (idx !== -1) {
      if (idx <= answers.length - 1) {
        newAnswers = _.filter(answers, (__, index) => index !== idx);
      } else {
        return false;
      }
    } else if (shouldResetSelection(id, answers)) {
      newAnswers = [id];
    } else {
      // check for max answers
      if (
        currentQuestion.maxAnswers &&
        currentQuestion.maxAnswers === answers.length
      ) {
        setHasError(true);
        return false;
      }
      newAnswers = [...answers, id];
    }

    const newRecords = clearInconsistencies({
      newRecord: newAnswers,
      originQuestionId: currentQuestion.id,
      questions,
      records: questionAnswers,
    });

    setQuestionAnswers(newRecords);
  };

  const selectVerbatim = useCallback(
    answers => {
      const newRecords = clearInconsistencies({
        newRecord: answers,
        originQuestionId: currentQuestion.id,
        questions,
        records: questionAnswers,
      });

      setQuestionAnswers(newRecords);
    },
    [currentQuestion.id, questionAnswers, questions, setQuestionAnswers]
  );

  return {
    selectAnswer,
    selectMatrix,
    selectMultiLevel,
    selectNPS,
    selectRank,
    selectVerbatim,
  };
};
