import { useMemo, useRef, useState } from 'react';

import styles from './slider.module.scss';

const DEFAULT_VALUE = 5;

type SliderProps = {
  currentAnswer?: number;
  selectNPS: (value: number) => void;
};

export const Slider = ({ selectNPS, currentAnswer }: SliderProps) => {
  const [backgroundSize, setBackgroundSize] = useState(DEFAULT_VALUE * 10);

  const ref = useRef<HTMLInputElement>(null);
  const left = useMemo(
    () =>
      currentAnswer
        ? `calc(${backgroundSize}% - ${currentAnswer / 10} * 38px )`
        : undefined,
    [backgroundSize, currentAnswer]
  );
  return (
    <>
      <input
        ref={ref}
        className={styles.slider}
        type="range"
        onTouchStart={() => selectNPS(Number(ref.current?.defaultValue))}
        onClick={() => selectNPS(Number(ref.current?.defaultValue))}
        onChange={e => {
          const { value } = e.currentTarget;
          selectNPS(Number(value));
          setBackgroundSize(Number(value) * 10);
        }}
        style={{ backgroundSize: `${backgroundSize}% 100%` }}
        value={currentAnswer}
        defaultValue={DEFAULT_VALUE}
        min={0}
        max={10}
        step={1}
      />
      <div className={styles.npsValue} style={{ left }}>
        {currentAnswer}
      </div>
    </>
  );
};
