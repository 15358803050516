import { useCallback, useMemo, useState } from 'react';

import { version } from 'lib/version';

import { Data, DataFunction, UseApiProps } from './types';

export const useApi = ({ apiUrl, id, visitToken }: UseApiProps) => {
  const [sentData, setSentData] = useState<Data>({});
  const [skippedData, setSkippedData] = useState<Data>({});

  const pixelImageData = useMemo<Data>(
    () => ({ ...sentData, ...skippedData }),
    [sentData, skippedData]
  );

  const generateSrc = useCallback(
    ({ path, urlParams = '' }): string => {
      const urlSearchParams = new URLSearchParams(urlParams);
      urlSearchParams.append('visit_token', visitToken);
      urlSearchParams.append('version', version);

      return `${apiUrl}/v/campaigns/${id}/${path}?${urlSearchParams.toString()}`;
    },
    [apiUrl, id, visitToken]
  );

  /**
   * Stores a new request to the sent data array
   * @param {object} request - The request object that will be parsed by the generateSrc
   */
  const sendData = useCallback<DataFunction>(
    (originId, request) => {
      setSentData(currentSentData => ({
        ...currentSentData,
        [originId]: generateSrc(request),
      }));
    },
    [generateSrc]
  );

  /**
   * Stores a new request to the skip data array
   * @param {object} request - The request object that will be parsed by the generateSrc
   */
  const skipData = useCallback<DataFunction>(
    (originId, request) => {
      setSkippedData(currentSentData => ({
        ...currentSentData,
        [originId]: generateSrc(request),
      }));
    },
    [generateSrc]
  );

  return { pixelImageData, sendData, skipData };
};
