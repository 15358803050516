import { RefObject } from 'react';

import { AnswerVI } from 'types/campaign';
import { Dimension as DimensionType } from 'types/dimension';

import { Media, MediaProps } from '../media';
import { Question } from '../shared/question';
import { Dimension } from './dimension/Dimension';

type MatrixQuestionProps = MediaProps & {
  body: string;
  hint?: string;
  answers: AnswerVI[];
  dimensions: DimensionType[];
  active: boolean;
  currentAnswer: { [key: string]: any };
  currentDimensionIndex: number;
  selectAnswer: (dimensionId: number, answerId: number) => void;
  stepRef: RefObject<HTMLDivElement>;
};

export function MatrixQuestion({
  answers,
  body,
  currentAnswer,
  currentDimensionIndex,
  dimensions,
  hint,
  selectAnswer,
  stepRef,
  ...mediaProps
}: MatrixQuestionProps) {
  const { audio, video, image } = mediaProps;
  const hasMedia = audio || video || image;

  return (
    <Question body={body} hint={hint}>
      <Media {...mediaProps} />
      <Dimension
        stepRef={stepRef}
        answers={answers}
        currentAnswer={currentAnswer}
        dimensions={dimensions}
        dimensionsCount={dimensions.length}
        index={currentDimensionIndex}
        selectAnswer={selectAnswer}
        hasMedia={!!hasMedia}
      />
    </Question>
  );
}
