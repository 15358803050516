import { useMemo } from 'react';

import ELEMENT from 'constant/element';

import { ParticipantVI, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { isNoneInRecord } from './utils/answerAnalysis';

export const useNextReady = ({
  currentDimensionIndexes,
  currentElement,
  currentIndex,
  currentQuestion,
  participant,
  records,
}: {
  currentDimensionIndexes: { [key: string]: number };
  currentElement: string;
  currentIndex: number;
  currentQuestion: QuestionVI;
  participant: ParticipantVI;
  records: { [questionId: string]: any };
}) => {
  return useMemo(() => {
    if (currentElement === ELEMENT.participant) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        participant.email.trim()
      );
    }

    const {
      answers: currentQuestionAnswers,
      id: currentQuestionId,
      questionType: currentQuestionType,
    } = currentQuestion;

    const record = records[currentQuestionId];

    switch (currentQuestionType) {
      case QUESTION_TYPES.simple: {
        return record.length > 0;
      }
      case QUESTION_TYPES.multi:
      case QUESTION_TYPES.ranking: {
        if (isNoneInRecord(record, currentQuestionAnswers)) return true;

        const { maxAnswers, minAnswers } = currentQuestion;

        const max = maxAnswers || currentQuestionAnswers.length;
        const min = minAnswers || 1;

        return record.length <= max && record.length >= min;
      }
      case QUESTION_TYPES.radar:
      case QUESTION_TYPES.wordPairs: {
        return Object.values(record).length === currentQuestionAnswers.length;
      }
      case QUESTION_TYPES.nps: {
        return record !== undefined;
      }
      case QUESTION_TYPES.matrix: {
        const { dimensions, multipleAnswers } = currentQuestion;

        const currentDimensionIndex = currentDimensionIndexes[currentIndex];

        const { id: currentDimensionId } =
          dimensions?.[currentDimensionIndex] || {};

        const currentAnswerIds = currentDimensionId
          ? record[currentDimensionId]
          : [];

        return multipleAnswers
          ? isNoneInRecord(currentAnswerIds, currentQuestionAnswers) ||
              currentAnswerIds.length > 0
          : currentAnswerIds.length > 0;
      }
      case QUESTION_TYPES.verbatim: {
        return record.some((verbatim: string) => verbatim !== '');
      }
      default:
        return true;
    }
  }, [
    currentDimensionIndexes,
    currentElement,
    currentIndex,
    currentQuestion,
    participant,
    records,
  ]);
};
