import { memo } from 'react';

import cn from 'classnames';

import { useTranslations } from 'hooks/useTranslations';

import { useVerbatim } from './useVerbatim';

import styles from './verbatim.module.scss';

type VerbatimProps = {
  disabled?: boolean;
  isFocused: boolean;
  multipleVerbatim: boolean;
  onChange: (text: any, position?: any) => void;
  onFocus: () => void;
  onSubmit: () => void;
  position: any;
  verbatim: any;
};

const Verbatim = ({
  disabled = false,
  isFocused,
  multipleVerbatim,
  onChange,
  onFocus,
  onSubmit,
  position,
  verbatim,
}: VerbatimProps) => {
  const { t } = useTranslations();

  const { handleBlur, handleChange, handleClick, handleSubmit, ref } =
    useVerbatim({
      isFocused,
      multipleVerbatim,
      onChange,
      onSubmit,
      position,
      verbatim,
    });

  return (
    <form onSubmit={handleSubmit}>
      <input
        ref={ref}
        id={isFocused ? 'focused-verbatim-input' : undefined}
        className={cn(styles.verbatimInput, { [styles.selected]: !!verbatim })}
        dir="auto"
        disabled={disabled}
        type="text"
        placeholder={t('verbatim_placeholder')}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onFocus={onFocus}
        enterKeyHint="done"
        value={verbatim}
      />
      <input className={styles.submitButton} type="submit" />
    </form>
  );
};

const MemoVerbatim = memo(Verbatim);

export { MemoVerbatim as Verbatim };
