export enum QUESTION_TYPES {
  matrix = 'matrix',
  multi = 'multi',
  nps = 'nps',
  radar = 'radar',
  ranking = 'ranking',
  simple = 'simple',
  verbatim = 'verbatim',
  wordPairs = 'word_pairs',
}
