import styles from '../buttons.module.scss';

type ButtonLinkProps = {
  externalLink: string;
  externalText: string;
};

export function ButtonLink({ externalLink, externalText }: ButtonLinkProps) {
  return (
    <a
      className={styles.buttonLink}
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {externalText}
    </a>
  );
}
