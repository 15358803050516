import { ReactNode } from 'react';

import cn from 'classnames';
import _ from 'lodash';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import styles from './question.module.scss';

type QuestionProps = {
  children: ReactNode;
  className?: string;
  body: string;
  hint?: string;
  hasError?: boolean;
  subHint?: string;
};

export const Question = ({
  children,
  className,
  body,
  hint,
  hasError,
  subHint,
}: QuestionProps) => {
  const { isLandscape } = useScreenOrientation();
  const hints = _.compact([hint, subHint]);

  return (
    <hdm-stack
      class={cn(styles.question, { [styles.isLandscape]: isLandscape })}
    >
      <div>
        <hdm-stack class={styles.content} dir="auto">
          <div className={styles.body}>{body}</div>
          {hints.map(hintLabel => (
            <div
              key={hintLabel}
              className={cn(styles.hint, { [styles.error]: hasError })}
            >
              {hintLabel}
            </div>
          ))}
        </hdm-stack>
      </div>
      <div>
        <hdm-stack class={cn(styles.contentStack, className)}>
          {children}
        </hdm-stack>
      </div>
    </hdm-stack>
  );
};
