import { memo } from 'react';

import cn from 'classnames';

import { useAssetUrl } from 'hooks/useAssetUrl';
import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { MediaAnswerItem } from 'components/rootNavigation/surveyScreen/steps/questions/shared/answerItems/mediaAnswerItems/mediaAnswerItem';

import { Counter } from '../counter/Counter';

import styles from './ImageItem.module.scss';

type ImageItemProps = {
  body?: string;
  dimensionsCount: number;
  image: string;
  index: number;
};

const ImageItem = ({ body, dimensionsCount, image, index }: ImageItemProps) => {
  const src = useAssetUrl(image);
  const { isLandscape } = useScreenOrientation();

  return (
    <div
      className={cn(styles.dimensionImage, {
        [styles.isLandscape]: isLandscape,
      })}
    >
      <hdm-stack class={styles.imageBodyContainer}>
        <div className={styles.legendCounterContainer}>
          {body && <div>{body}</div>}
          <Counter dimensionsCount={dimensionsCount} index={index} />
        </div>
        <div>
          <MediaAnswerItem
            className={styles.mediaContainer}
            answerId={0}
            body={`dimension-${body}`}
            image={src}
            disabled
          />
        </div>
      </hdm-stack>
    </div>
  );
};

const ImageItemMemo = memo(ImageItem);

export { ImageItemMemo as ImageItem };
