import { memo } from 'react';

import { useTranslations } from 'hooks/useTranslations';

import { HappydemicsLogo } from 'components/shared/happydemicsLogo/HappydemicsLogo';

import { ButtonLink } from '../../../../shared/buttons/buttonLink/ButtonLink';
import { CloseButton } from '../../../../shared/buttons/closeButton/CloseButton';

import { useThankYouStep } from './useThankYouStep';

import styles from './ThankYouStep.module.scss';

type ThankYouStepProps = {
  active: boolean;
};

function ThankYouStep({ active }: ThankYouStepProps) {
  const { displayCloseButton, closeInterface } = useThankYouStep(active);
  const { t } = useTranslations();

  const externalLink = t('voting_link_url');
  const externalText = t('voting_link_text');

  return (
    <hdm-stack class={styles.thankYouStep}>
      <HappydemicsLogo />
      <div className={styles.thankYouText} dir="auto">
        {t('voting_thank_you_text')}
      </div>
      {externalLink && externalText && (
        <ButtonLink externalLink={externalLink} externalText={externalText} />
      )}
      {displayCloseButton && <CloseButton onClick={closeInterface} />}
    </hdm-stack>
  );
}

const MemoThankYouStep = memo(ThankYouStep);

export { MemoThankYouStep as ThankYouStep };
