import { useCallback, useState } from 'react';

export const useFullscreenControl = () => {
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [hiding, setHiding] = useState(true);

  const show = useCallback(() => {
    setFullscreenMode(true);
    setTimeout(() => {
      setHiding(false);
    }, 10);
  }, []);

  const close = useCallback(() => {
    setHiding(true);
    setTimeout(() => {
      setFullscreenMode(false);
    }, 100);
  }, []);

  return { close, fullscreenMode, hiding, show };
};
