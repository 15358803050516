import { useCallback } from 'react';

import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { RankingNumber } from '../../rankingNumber';

import styles from './TextAnswerItem.module.scss';

type ClassicButtonProps = {
  answer: AnswerVI;
  isRanking?: boolean;
  selectAnswer: (answerId: any) => void;
  selectedAnswer?: any[];
};

export function TextAnswerItem({
  answer,
  isRanking,
  selectAnswer,
  selectedAnswer = [],
}: ClassicButtonProps) {
  const { id, anchored, body, none } = answer;

  const selected = selectedAnswer.includes(id);

  const displayRank = isRanking && selected && !none;

  const onClick = useCallback(
    e => {
      e.target.blur();
      selectAnswer(id);
    },
    [id, selectAnswer]
  );

  return (
    <div>
      <button
        className={cn(styles.textAnswerItem, {
          [styles.anchored]: anchored,
          [styles.selected]: selected,
          [styles.ranking]: displayRank,
        })}
        dir="auto"
        onClick={onClick}
        type="button"
      >
        {body}
        {displayRank && (
          <RankingNumber selectedAnswer={selectedAnswer} answerId={id} />
        )}
      </button>
    </div>
  );
}
