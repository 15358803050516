import cn from 'classnames';

import happydemicsLogo from './logo_hdm.svg';

import styles from './HappydemicsLogo.module.scss';

type HappydemicsLogoProps = {
  className?: string;
};

export const HappydemicsLogo = ({ className }: HappydemicsLogoProps) => (
  <img
    className={cn(styles.hdmLogo, className)}
    alt="Happydemics"
    src={happydemicsLogo}
  />
);
