import { FC } from 'react';

import { AD_WRAPPER_EVENTS } from 'constant/adWrapperEvents';

import { useAdWrapper } from 'hooks/useAdWrapper';

import { AnswerButtonProps } from './answerButton.type';

import './answerButton.scss';

export const AnswerButton: FC<AnswerButtonProps> = ({
  displayLastScreen,
  interactive,
  negativeAnswer,
  positiveAnswer,
  redirectionUrl,
}) => {
  const { notifyAdWrapper } = useAdWrapper();

  const handleNegativeAnswer = () => {
    if (interactive) {
      notifyAdWrapper(AD_WRAPPER_EVENTS.closeRequested);
    } else {
      displayLastScreen();
    }
  };

  return (
    <div className="recruitment-answer-button">
      <button className="negative" type="button" onClick={handleNegativeAnswer}>
        {negativeAnswer}
      </button>
      <a
        className="positive"
        href={redirectionUrl}
        target="_blank"
        rel="noreferrer"
      >
        {positiveAnswer}
      </a>
    </div>
  );
};
