import { useMemo } from 'react';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

export const useInconsistencies = ({
  questionAnswers,
  questions,
}: {
  questionAnswers: { [questionId: string]: any };
  questions: QuestionVI[];
}) =>
  useMemo(
    () =>
      questions.map(({ answers }) =>
        answers.filter(({ parentDimensionId, parentId }) => {
          if (!parentId) return true;

          const parentQuestion = questions.find(
            ({ answers: candidateAnswers }) =>
              candidateAnswers.some(
                ({ id: candidateAnswerId }) => candidateAnswerId === parentId
              )
          );
          if (!parentQuestion) return true;

          const { id: parentQuestionId, questionType: parentQuestionType } =
            parentQuestion;

          const parentAnswers = questionAnswers[parentQuestionId];

          switch (parentQuestionType) {
            case QUESTION_TYPES.matrix: {
              const parentDimensionAnswers = parentDimensionId
                ? parentAnswers?.[parentDimensionId]
                : [];
              return parentDimensionAnswers.includes(parentId);
            }
            case QUESTION_TYPES.simple:
            case QUESTION_TYPES.multi:
            case QUESTION_TYPES.ranking:
              return parentAnswers.includes(parentId);
            default:
              return true;
          }
        })
      ),
    [questionAnswers, questions]
  );
