import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { Media } from '../media';
import { LevelButtons } from '../shared/levelButtons';
import { Question } from '../shared/question';

import styles from './WordPairQuestion.module.scss';

type WordPairQuestionProps = {
  active: boolean;
  audio?: string;
  audioType: string;
  answers: AnswerVI[];
  currentAnswer: any;
  body: string;
  hint?: string;
  image?: string;
  selectAnswer: (index: number, value: any) => void;
  video?: string;
};

const MAX_LEVEL = 3;

export function WordPairQuestion({
  active,
  audio,
  audioType,
  answers,
  currentAnswer,
  body,
  hint,
  image,
  selectAnswer,
  video,
}: WordPairQuestionProps) {
  return (
    <Question body={body} hint={hint} className={styles.answers}>
      <Media
        active={active}
        audio={audio}
        audioType={audioType}
        image={image}
        video={video}
      />
      {answers.map(({ bodyLeft, bodyRight, id }: AnswerVI) => (
        <div key={id.toString()}>
          <hdm-stack class={styles.answer}>
            <div className={styles.header}>
              <div
                style={{
                  opacity: Math.max(
                    0.25,
                    currentAnswer[id] === undefined
                      ? 1
                      : 1 - currentAnswer[id] / 6
                  ),
                }}
                dir="auto"
              >
                {bodyLeft}
              </div>
              <div
                className={cn(styles.answerBody, styles.right)}
                dir="auto"
                style={{
                  opacity: Math.max(
                    0.25,
                    currentAnswer[id] === undefined ? 1 : currentAnswer[id] / 6
                  ),
                }}
              >
                {bodyRight}
              </div>
            </div>
            <LevelButtons
              min={-MAX_LEVEL}
              max={MAX_LEVEL}
              className={styles.wordPairLevels}
              selectLevel={(level: number) =>
                selectAnswer(id, level + MAX_LEVEL)
              }
              currentAnswer={currentAnswer[id] - MAX_LEVEL}
            />
          </hdm-stack>
        </div>
      ))}
    </Question>
  );
}
