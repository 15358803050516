import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { Media } from '../media';
import { AnswerItems } from '../shared/answerItems';
import { Question } from '../shared/question';

import styles from './basicQuestion.module.scss';

type BasicQuestionProps = {
  answers: AnswerVI[];
  body: string;
  currentAnswer: any[];
  hasError?: boolean;
  hint?: string;
  isRanking?: boolean;
  selectAnswer: (answerId: any) => void;
  active: boolean;
  audio?: string;
  audioType: string;
  image?: string;
  video?: string;
};

export function BasicQuestion({
  answers,
  body,
  currentAnswer,
  hasError,
  hint,
  isRanking,
  selectAnswer,
  ...mediaProps
}: BasicQuestionProps) {
  const { image, audio, video } = mediaProps;
  const { isLandscape } = useScreenOrientation();

  return (
    <Question body={body} hint={hint} hasError={hasError}>
      <hdm-switcher
        class={cn(styles.contentSwitcher, {
          [styles.isLandscape]: isLandscape,
        })}
      >
        <Media {...mediaProps} />
        <AnswerItems
          answers={answers}
          isRanking={isRanking}
          selectAnswer={selectAnswer}
          selectedAnswer={[...currentAnswer]}
          hasMedia={!!(image || audio || video)}
        />
      </hdm-switcher>
    </Question>
  );
}
