import { DataFunction } from 'components/api/useApi/types';

export const sendSkip = ({
  by,
  ids,
  skipData,
}: {
  by: string;
  ids: number[];
  skipData: DataFunction;
}) => {
  ids.forEach(qid => {
    skipData(`question_${qid}`, {
      path: `question_answers/${by}`,
      urlParams: `qa[q]=${qid}`,
    });
  });
};
