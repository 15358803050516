import { createContext } from 'react';

import { Parameters } from 'types/parameters';

export const defaultParameters = {
  adWrapperCore: undefined,
  apiUrl: '',
  assetsUrl: '',
  campaign: undefined,
  interactive: false,
  isParticipantRecruited: false,
  preview: false,
  redirectionUrl: undefined,
  visitToken: '',
};

export const ParametersContext = createContext<Parameters>(defaultParameters);
