import { ChangeEvent, memo } from 'react';

import cn from 'classnames';

import checkIcon from 'assets/img/icons/check.svg';

import { EmailScreen, ParticipantVI } from 'types/campaign';

import { useTranslations } from 'hooks/useTranslations';

import './emailStep.scss';

type EmailStepProps = {
  emailScreen: EmailScreen;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  participant: ParticipantVI;
};

function EmailStep({
  emailScreen: { message, title, validationCheckboxText },
  handleEmailChange,
  participant: { email, emailValid },
}: EmailStepProps) {
  const { t } = useTranslations();

  return (
    <div className="question email-step">
      <div className="stack">
        <h5>{title}</h5>
        <p>{message}</p>
      </div>
      <div className="stack">
        <div className="input-container">
          <input
            onChange={handleEmailChange}
            value={email}
            type="email"
            placeholder={t('email')}
            className={cn('mail-input', {
              'mail-input--correct': emailValid,
            })}
          />
          <img alt="checked" className="icon-check" src={checkIcon} />
        </div>
        <p className="gdpr">{validationCheckboxText}</p>
      </div>
    </div>
  );
}

const MemoEmailStep = memo(EmailStep);

export { MemoEmailStep as EmailStep };
