import { MutableRefObject, useEffect } from 'react';

export const useVolume = (
  {
    current: player,
  }: MutableRefObject<HTMLVideoElement | HTMLAudioElement | null>,
  volume = 0.5
) => {
  useEffect(() => {
    if (player === null) return;

    // eslint-disable-next-line no-param-reassign
    player.volume = volume;
  }, [volume, player]);
};
