import { CampaignVI } from 'types/campaign';

import { shuffle } from './shuffle';

export const applyRandomRules = (campaign: CampaignVI): CampaignVI => {
  const { questions: rawQuestions } = campaign;

  const questions = rawQuestions.map(rawQuestion => {
    const { answers, dimensions, randomDimensionsOrder, randomOrder } =
      rawQuestion;
    return {
      ...rawQuestion,
      answers: (randomOrder
        ? shuffle(answers.filter(({ anchored }) => !anchored))
        : answers.filter(({ anchored }) => !anchored)
      ).concat(answers.filter(({ anchored }) => anchored)),
      dimensions: randomDimensionsOrder ? shuffle(dimensions) : dimensions,
    };
  });

  return {
    ...campaign,
    questions,
  };
};
