import { IllustrationTypeProps } from './illustration.type';

import communicationLeft from './communication_left.svg';
import communicationRight from './communication_right.svg';
import defaultIllustration from './default_illustration.svg';

import './illustration.scss';

export const Illustration = ({ image }: IllustrationTypeProps) => {
  return (
    <div className="recruitment-illustration">
      {image && <img alt="illustration" src={communicationLeft} />}
      <img alt="illustration" src={image || defaultIllustration} />
      {image && <img alt="illustration" src={communicationRight} />}
    </div>
  );
};
