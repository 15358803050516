import { useState } from 'react';

export const useModal = () => {
  const [displayedModal, setDisplayedModal] = useState(false);

  const openModal = () => setDisplayedModal(true);
  const closeModal = () => setDisplayedModal(false);

  return {
    closeModal,
    displayedModal,
    openModal,
  };
};
