import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

export const initializeQuestionAnswers = (
  questions: QuestionVI[],
  selectedAnswer: { [questionId: string]: any } = {}
) => {
  const answers = selectedAnswer;
  questions.forEach(question => {
    switch (question.questionType) {
      case QUESTION_TYPES.nps:
        answers[question.id] = undefined;
        break;
      case QUESTION_TYPES.simple:
      case QUESTION_TYPES.multi:
      case QUESTION_TYPES.ranking:
      case QUESTION_TYPES.verbatim:
        answers[question.id] = [];
        break;
      case QUESTION_TYPES.radar:
      case QUESTION_TYPES.wordPairs: {
        answers[question.id] = {};
        break;
      }
      case QUESTION_TYPES.matrix: {
        const { dimensions = [] } = question;
        answers[question.id] = dimensions.reduce((acc, dimension) => {
          acc[dimension.id] = [];
          return acc;
        }, {} as { [dimensionId: string]: [] });
        break;
      }
      default:
        answers[question.id] = '';
        break;
    }
  });
  return answers;
};
