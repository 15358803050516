import { Media } from '../media';
import { Question } from '../shared/question';
import { Slider } from './slider';

import styles from './npsQuestion.module.scss';

type NpsQuestionProps = {
  active: boolean;
  audio?: string;
  audioType: string;
  body: string;
  currentAnswer?: number;
  hint: string;
  image?: string;
  npsHighLabel: string;
  npsLowLabel: string;
  selectNPS: (value: any) => void;
  video?: string;
};

const NpsQuestion = ({
  active,
  audio,
  audioType,
  body,
  currentAnswer,
  hint,
  image,
  npsHighLabel,
  npsLowLabel,
  selectNPS,
  video,
}: NpsQuestionProps) => {
  const computeNpsHint = () => {
    return [npsHighLabel, npsLowLabel].every(
      element => element != null && element !== ''
    )
      ? `0 = ${npsLowLabel}; 10 = ${npsHighLabel}`
      : undefined;
  };
  const npsHint = computeNpsHint();

  return (
    <Question body={body} hint={hint} subHint={npsHint}>
      <Media
        active={active}
        audio={audio}
        audioType={audioType}
        image={image}
        video={video}
      />
      <div className={styles.container}>
        <Slider selectNPS={selectNPS} currentAnswer={currentAnswer} />
      </div>
    </Question>
  );
};

export default NpsQuestion;
