import { CampaignVI } from 'types/campaign';

const showVideoAsk = (videoAskId: string) => {
  if (videoAskId) {
    const script = document.createElement('script');
    script.src = 'https://www.videoask.com/embed/embed.js';
    script.async = true;
    script.onload = () => {
      window.videoask.loadEmbed({
        kind: 'widget',
        options: {
          backgroundColor: '#FF6584',
          position: 'bottom-right',
          text: '',
          widgetType: 'VideoThumbnailWindowTall',
        },
        url: `https://www.videoask.com/${videoAskId}`,
      });
    };
    document.body.appendChild(script);
  }
};

export const thankScreenCallbacks = (campaign: CampaignVI) => {
  showVideoAsk(campaign.videoAskId);
};
