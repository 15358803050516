import { RefObject, useMemo } from 'react';

import cn from 'classnames';

import { AnswerVI } from 'types/campaign';
import { Dimension as DimensionType } from 'types/dimension';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { AnswerItems } from '../../shared/answerItems';
import { Item } from './item/Item';

import { useDimensions } from './useDimensions';
import { useScrollToTop } from './useScrollToTop';

import styles from './Dimension.module.scss';

type DimensionProps = {
  answers: AnswerVI[];
  dimensions: DimensionType[];
  dimensionsCount: number;
  currentAnswer: { [key: string]: any };
  hasMedia: boolean;
  index: number;
  selectAnswer: (dimensionId: number, answerId: number) => void;
  stepRef: RefObject<HTMLDivElement>;
};

export function Dimension({
  answers,
  dimensions,
  dimensionsCount,
  currentAnswer,
  hasMedia,
  index,
  selectAnswer,
  stepRef,
}: DimensionProps) {
  const { isLandscape } = useScreenOrientation();

  const { currentDimension, hide, scaleDown } = useDimensions({
    dimensions,
    index,
  });

  const { image: dimensionImage, id: dimensionId } = currentDimension;

  const handleSelectAnswer = (answerId: number) => {
    selectAnswer(dimensionId, answerId);
  };

  const selectedAnswer = useMemo(
    () => currentAnswer[dimensionId],
    [currentAnswer, dimensionId]
  );

  useScrollToTop(hide, selectedAnswer, stepRef);

  return (
    <hdm-switcher
      class={cn(styles.dimension, {
        [styles.hide]: hide,
        [styles.scaleDown]: scaleDown,
        [styles.hasDimensionImage]: !!dimensionImage,
        [styles.isLandscape]: isLandscape && !hasMedia,
      })}
    >
      <Item
        currentDimension={currentDimension}
        index={index}
        dimensionsCount={dimensionsCount}
      />
      <AnswerItems
        answers={answers}
        selectAnswer={handleSelectAnswer}
        selectedAnswer={selectedAnswer}
        oneColumn={!!dimensionImage && isLandscape}
      />
    </hdm-switcher>
  );
}
