import { memo, useContext } from 'react';

import { ParametersContext } from 'contexts/parametersContext';

import { Api } from './api';
import { ErrorBoundary } from './errorBoundary';
import { ErrorPage } from './errorBoundary/errorPage';
import { RootNavigation } from './rootNavigation';

function App() {
  const {
    apiUrl,
    campaign,
    interactive,
    isParticipantRecruited,
    preview,
    redirectionUrl,
    visitToken,
  } = useContext(ParametersContext);

  if (!campaign) {
    return <ErrorPage />;
  }

  const { id } = campaign;

  return (
    <ErrorBoundary>
      <Api apiUrl={apiUrl} id={id} preview={preview} visitToken={visitToken}>
        {apiMethods => (
          <RootNavigation
            campaign={campaign}
            interactive={interactive}
            isParticipantRecruited={isParticipantRecruited}
            preview={preview}
            redirectionUrl={redirectionUrl}
            {...apiMethods}
          />
        )}
      </Api>
    </ErrorBoundary>
  );
}

const MemoApp = memo(App);

export { MemoApp as App };
