import React, { memo, useEffect, useState } from 'react';

import { Parameters } from 'types/parameters';
import { Translations } from 'types/translations';

import { useAdWrapper } from 'hooks/useAdWrapper';

import {
  ParametersContext,
  defaultParameters,
} from 'contexts/parametersContext';
import { TranslationsContext } from 'contexts/translationsContext';

import { applyRandomRules } from './utils/applyRandomRules';

type AdWrapperProps = {
  children: React.ReactNode;
};

/**
 * Wrapper used to receive the voting interface parameter, sent by the ad wrapper, via a post message
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
function AdWrapper({ children }: AdWrapperProps) {
  const { notifyAdWrapper } = useAdWrapper();
  const [listening, setListening] = useState<boolean>(true);

  const [parameters, setParameters] = useState<Parameters>(defaultParameters);
  const [translations, setTranslations] = useState<Translations>({});

  useEffect(() => {
    window.PARAMETERS_LOADER.then((rawParameters = {}) => {
      setListening(false);

      setParameters({
        adWrapperCore: rawParameters.adWrapperCore,
        apiUrl: rawParameters.apiUrl || '',
        assetsUrl: rawParameters.assetsUrl || '',
        campaign: applyRandomRules(rawParameters.campaign || { questions: [] }),
        interactive: !!rawParameters.interactive,
        isParticipantRecruited: !!rawParameters.isParticipantRecruited,
        preview: !!rawParameters.preview,
        redirectionUrl: rawParameters.redirectionUrl || '',
        visitToken: rawParameters.visitToken || '',
      });
      setTranslations(rawParameters.translations || {});
    });
  }, []);

  useEffect(() => {
    if (!listening) return;

    const timeout = setTimeout(() => {
      setListening(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [listening, notifyAdWrapper]);

  if (listening)
    return (
      <div
        style={{ backgroundColor: 'white', height: '100%', width: '100%' }}
      />
    );

  return (
    <TranslationsContext.Provider value={translations}>
      <ParametersContext.Provider value={parameters}>
        {children}
      </ParametersContext.Provider>
    </TranslationsContext.Provider>
  );
}

const MemoAdWrapper = memo(AdWrapper);

export { MemoAdWrapper as AdWrapper };
