import { Dispatch } from 'react';

import { MatrixScenario, QuestionVI, ScenarioVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { DataFunction } from 'components/api/useApi/types';

import { ScenarioProps } from '../types';
import { initializeQuestionAnswers } from './initializeQuestionAnswers';
import { sendSkip } from './sendSkip';

type ResetScenarioAnswersProps = {
  questionAnswers: { [questionId: string]: any };
  questionIds: number[];
  questions: QuestionVI[];
  setQuestionAnswers: Dispatch<{ [key: string]: any }>;
  skipData: DataFunction;
};

const resetScenarioAnswers = ({
  questionAnswers,
  questionIds,
  questions,
  setQuestionAnswers,
  skipData,
}: ResetScenarioAnswersProps) => {
  const filteredQuestions = questions.filter(
    q => questionIds.indexOf(q.id) !== -1
  );

  const newAnswers = initializeQuestionAnswers(
    filteredQuestions,
    questionAnswers
  );

  sendSkip({ by: 'skip_by_scenario', ids: questionIds, skipData });
  setQuestionAnswers(newAnswers);
};

const isMatchingScenarioCondition = (
  scenario: ScenarioVI,
  currentQuestion: QuestionVI,
  currentAnswers: any
) => {
  switch (currentQuestion.questionType) {
    case QUESTION_TYPES.simple:
      return scenario.condition === 'include'
        ? scenario.answers.indexOf(currentAnswers[0]) !== -1
        : scenario.answers.indexOf(currentAnswers[0]) === -1;
    case QUESTION_TYPES.nps:
      return scenario.condition === 'include'
        ? scenario.answers.indexOf(currentAnswers) > -1
        : scenario.answers.indexOf(currentAnswers) === -1;
    case QUESTION_TYPES.multi:
    case QUESTION_TYPES.ranking: {
      const diff = scenario.answers.filter(x => currentAnswers.indexOf(x) < 0);
      return (
        (scenario.logicalCondition === 'and' &&
          (scenario.condition === 'include'
            ? diff.length === 0
            : diff.toString() === scenario.answers.toString())) ||
        (scenario.logicalCondition === 'or' &&
          (scenario.condition === 'include'
            ? diff.toString() !== scenario.answers.toString()
            : diff.length > 0))
      );
    }
    case QUESTION_TYPES.matrix:
      return (scenario as MatrixScenario).condition === 'include'
        ? currentAnswers[(scenario as MatrixScenario).dimensionId]?.includes(
            scenario.answers[0]
          )
        : !currentAnswers[(scenario as MatrixScenario).dimensionId]?.includes(
            scenario.answers[0]
          );
    default:
      return false;
  }
};

type ComputeSkipValuesProps = {
  currentIndex: number;
  currentQuestion: QuestionVI;
  questionAnswers: { [questionId: string]: any };
  questions: QuestionVI[];
  skipQuestions: {
    [key: string]: number[];
  };
};

export const computeSkipValues = ({
  currentIndex,
  currentQuestion,
  questionAnswers,
  questions,
  skipQuestions,
}: ComputeSkipValuesProps) => {
  const currentAnswers = questionAnswers[currentQuestion.id];
  const currentSkip = skipQuestions;
  let newSkip: number[] = [];
  const { questionType, scenarios = [] } = currentQuestion;
  if (
    (questionType === QUESTION_TYPES.nps ||
      questionType === QUESTION_TYPES.matrix ||
      currentAnswers.length > 0) &&
    scenarios?.length > 0
  ) {
    scenarios.forEach((scenario: ScenarioVI) => {
      if (
        isMatchingScenarioCondition(scenario, currentQuestion, currentAnswers)
      ) {
        if (scenario.skipAll) {
          newSkip = newSkip.concat(
            questions.slice(currentIndex + 1).map(q => q.id)
          );
          return;
        }
        if (scenario.nextQuestion) {
          newSkip = newSkip.concat(
            questions
              .slice(
                currentIndex + 1,
                questions.findIndex(q => q.id === scenario.nextQuestion)
              )
              .map(q => q.id)
          );
          return;
        }
        newSkip = newSkip.concat(scenario.skip);
      }
    });
  }

  currentSkip[currentQuestion.id] = newSkip;
  const allSkip: number[] = ([] as number[]).concat(
    ...Object.values(currentSkip)
  );

  return { allSkip, currentSkip };
};

type ApplyScenarioProps = {
  currentIndex: number;
  currentQuestion: QuestionVI;
  questionAnswers: { [key: string]: any };
  questions: QuestionVI[];
  skipData: DataFunction;
} & ScenarioProps;

export const applyScenario = ({
  currentIndex,
  currentQuestion,
  questionAnswers,
  questions,
  setAllSkipQuestions,
  setQuestionAnswers,
  setSkipQuestions,
  skipData,
  skipQuestions,
}: ApplyScenarioProps) => {
  const { allSkip, currentSkip } = computeSkipValues({
    currentIndex,
    currentQuestion,
    questionAnswers,
    questions,
    skipQuestions,
  });

  resetScenarioAnswers({
    questionAnswers,
    questionIds: allSkip,
    questions,
    setQuestionAnswers,
    skipData,
  });
  setSkipQuestions(currentSkip);
  setAllSkipQuestions(allSkip);

  return allSkip;
};
