import Div100vh from 'react-div-100vh';

import { ReactComponent as ArrowNavigationIcon } from 'assets/img/icons/arrow_navigation.svg';

import { useTranslations } from 'hooks/useTranslations';

import './gdprView.scss';

type GdprViewProps = {
  close: () => void;
};

export function GdprView({ close }: GdprViewProps) {
  const { t } = useTranslations();

  return (
    <Div100vh className="gdpr-view">
      <div className="head">
        <h5>{t('gdpr_page_title')}</h5>
      </div>
      <div className="texts">
        <div>
          <h5>{t('gdpr_data_title')}</h5>
          <div className="description">{t('gdpr_data_description')}</div>
        </div>
        <div>
          <h5>{t('gdpr_rights_title')}</h5>
          <div className="description">{t('gdpr_rights_description')}</div>
        </div>
        <div>
          <h5>{t('gdpr_security_title')}</h5>
          <div className="description">{t('gdpr_security_description')}</div>
        </div>
      </div>
      <button className="close" onClick={close} type="button">
        <ArrowNavigationIcon width="5px" />
        {t('gdpr_back_to_survey_button')}
      </button>
    </Div100vh>
  );
}
