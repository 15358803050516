import { useEffect } from 'react';

import { QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { useTranslations } from 'hooks/useTranslations';

import { isNoneInRecord } from './utils/answerAnalysis';

export function useAutoSwitch({
  currentDimensionIndexes,
  currentIndex,
  currentQuestion,
  goToNextDimension,
  isOnEndEdge,
  questionAnswers,
  submitVote,
}: {
  currentDimensionIndexes: { [key: string]: number };
  currentIndex: number;
  currentQuestion: QuestionVI;
  goToNextDimension: any;
  isOnEndEdge: (currentIndex: number) => boolean;
  questionAnswers: any;
  submitVote: () => void;
}) {
  const { t } = useTranslations();

  useEffect(() => {
    const {
      answers: currentQuestionAnswers,
      questionType: currentQuestionType,
      id: currentQuestionId,
    } = currentQuestion;

    switch (currentQuestionType) {
      case QUESTION_TYPES.matrix: {
        const { dimensions, multipleAnswers } = currentQuestion;

        const dimensionAnswers = questionAnswers[currentQuestionId];

        const currentDimensionIndex = currentDimensionIndexes[currentIndex];

        const { id: currentDimensionId } =
          dimensions?.[currentDimensionIndex] || {};

        const currentAnswerIds =
          currentDimensionId && dimensionAnswers
            ? dimensionAnswers[currentDimensionId]
            : [];

        const currentQuestionAnswerIds = currentQuestion.answers.map(
          (answer: any) => answer.id
        );

        if (
          (multipleAnswers &&
            isNoneInRecord(currentAnswerIds, currentQuestionAnswers)) ||
          (!multipleAnswers && currentAnswerIds.length > 0) ||
          currentQuestionAnswers.length === 1 ||
          (currentQuestion.answers.length === 2 &&
            isNoneInRecord(currentQuestionAnswerIds, currentQuestionAnswers))
        ) {
          if (!isOnEndEdge(currentIndex)) {
            goToNextDimension(currentIndex);
          } else {
            submitVote();
          }
        }

        break;
      }
      case QUESTION_TYPES.simple: {
        const answerIds = questionAnswers[currentQuestionId];

        if (answerIds.length > 0) {
          submitVote();
        }

        break;
      }
      case QUESTION_TYPES.multi:
      case QUESTION_TYPES.ranking: {
        const answerIds = questionAnswers[currentQuestionId];

        const currentQuestionAnswerIds = currentQuestion.answers.map(
          (answer: any) => answer.id
        );

        if (
          isNoneInRecord(answerIds, currentQuestionAnswers) ||
          currentQuestionAnswers.length === 1 ||
          (currentQuestion.answers.length === 2 &&
            isNoneInRecord(currentQuestionAnswerIds, currentQuestionAnswers))
        ) {
          submitVote();
        }

        break;
      }
      case QUESTION_TYPES.verbatim: {
        const values = questionAnswers[currentQuestionId];
        const prefilledAnswers = [
          t('verbatim_idk_text'),
          t('verbatim_no_time_text'),
        ];

        if (prefilledAnswers.includes(values[0])) {
          submitVote();
        }

        break;
      }
      default:
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionAnswers]);
}
