import { ProgressBar } from 'components/rootNavigation/surveyScreen/progressBar';

import { ThankYouStep } from '../../surveyScreen/steps/thankYouStep/ThankYouStep';

import './lastScreen.scss';

export interface LastScreenProps {
  interactive: boolean;
}

export const LastScreen = ({ interactive }: LastScreenProps) => (
  <>
    <ProgressBar progress={1} />
    <div className="last-screen">
      <ThankYouStep active={interactive} />
    </div>
  </>
);
