import React, { ReactElement, cloneElement, useRef } from 'react';

import cn from 'classnames';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import styles from './step.module.scss';

type StepProps = {
  children: ReactElement;
};

function Step({ children }: StepProps) {
  const { isLandscape } = useScreenOrientation();
  const ref = useRef<HTMLDivElement>(null);
  return (
    <hdm-center
      ref={ref}
      class={cn(styles.step, { [styles.isLandscape]: isLandscape })}
    >
      {cloneElement(children, { stepRef: ref })}
    </hdm-center>
  );
}

const MemoStep = React.memo(Step);

export { MemoStep as Step };
