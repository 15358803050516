import Div100vh from 'react-div-100vh';

import { Carousel } from './carousel';

import { CampaignVI } from 'types/campaign';

import { DataFunction } from 'components/api/useApi/types';
import { HappydemicsLogo } from 'components/shared/happydemicsLogo/HappydemicsLogo';

import { CookiesBanner } from './cookiesBanner';
import { Footer } from './footer';
import { ProgressBar } from './progressBar';

import { useSurveyScreen } from './useSurveyScreen';

import styles from './surveyScreen.module.scss';

type SurveyScreenProps = {
  campaign: CampaignVI;
  sendData: DataFunction;
  skipData: DataFunction;
};

export function SurveyScreen({
  campaign,
  sendData,
  skipData,
}: SurveyScreenProps) {
  const {
    currentIndex,
    isParticipantElement,
    next,
    nextDisabled,
    nextHidden,
    prev,
    prevReady,
    steps,
  } = useSurveyScreen({ campaign, sendData, skipData });

  const { cookiesText, questions, introScreen } = campaign;

  const progress = currentIndex / questions.length;

  return (
    <>
      <Div100vh id="survey-screen" className={styles.surveyScreen}>
        <ProgressBar progress={progress} />
        <HappydemicsLogo className={styles.happydemicsLogo} />
        <hdm-stack class={styles.surveyStack}>
          <CookiesBanner
            forceHide={currentIndex !== 0}
            introScreen={!!introScreen}
            cookiesText={cookiesText}
          />
          <Carousel currentIndex={currentIndex}>{steps}</Carousel>
          <Footer
            isParticipantElement={isParticipantElement}
            next={next}
            nextDisabled={nextDisabled}
            nextHidden={nextHidden}
            prev={prev}
            prevReady={prevReady}
          />
        </hdm-stack>
      </Div100vh>
      <div id="fullscreen-image-container" />
    </>
  );
}
