import { AnswerVI, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

export const isNoneInRecord = (
  answerIds: number[],
  questionAnswers: AnswerVI[]
) => {
  return answerIds.some(answerId => {
    const answer =
      questionAnswers.find(a => a.id === answerId) || ({} as AnswerVI);
    return !!answer.none;
  });
};

export const questionHasConsistentAnswers = (
  answers: AnswerVI[],
  questionType: QuestionVI['questionType']
) =>
  [QUESTION_TYPES.nps, QUESTION_TYPES.verbatim].includes(
    questionType as QUESTION_TYPES
  ) ||
  (answers.length > 0 && answers.some(({ none }) => !none));
