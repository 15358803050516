import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import _ from 'lodash';

import styles from './levelButtons.module.scss';

const WORD_PAIR_LEVEL_COUNT = 7;

export type LevelButtonsProps = {
  className?: string;
  min?: number;
  max?: number;
  selectLevel: (level: number) => void;
  currentAnswer: any;
};

export const LevelButtons = ({
  className,
  min = 0,
  max = 10,
  selectLevel,
  currentAnswer,
}: LevelButtonsProps) => {
  const numbers = _.range(min, max + 1);
  const [width, setWidth] = useState('100%');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Display exception for word pair which takes all the space on one line.
    if (ref.current !== null && numbers.length !== WORD_PAIR_LEVEL_COUNT) {
      setWidth(`${ref.current.offsetWidth / 6 - 5}px`);
    }
  }, [numbers.length]);

  return (
    <div ref={ref} className={className}>
      {numbers.map(num => (
        <button
          key={num}
          type="button"
          onClick={() => selectLevel(num)}
          className={cn(styles.numbers, {
            [styles.isSelected]: num === currentAnswer,
          })}
          style={{ width }}
        >
          {Math.abs(num)}
        </button>
      ))}
    </div>
  );
};
