import cn from 'classnames';

import { Step } from './step';

import styles from './carousel.module.scss';

type CarouselProps = {
  children: any[];
  currentIndex: number;
};

/**
 * temp-verbatim-input is used to bypass disabled autofocus on input for
 * Apple devices
 */
export const Carousel = ({ children, currentIndex }: CarouselProps) => (
  <div className={styles.carousel} id="survey-screen-body">
    <input id="temp-verbatim-input" className={styles.tempVerbatimInput} />
    <div
      className={styles.slider}
      style={{ transform: `translateX(-${100 * currentIndex}%)` }}
    >
      {children.map((child, index) => (
        <div
          className={cn(styles.sliderElement, {
            [styles.visible]: index === currentIndex,
          })}
          // See https://github.com/yannickcr/eslint-plugin-react/issues/1123
          // eslint-disable-next-line react/no-array-index-key
          key={`step-${index}`}
        >
          <Step>{child}</Step>
        </div>
      ))}
    </div>
  </div>
);
