import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { MediaAnswerItem } from './mediaAnswerItem/index';

import styles from './mediaAnswerItems.module.scss';

type MediaAnswerItemsProps = {
  answers: AnswerVI[];
  isRanking?: boolean;
  selectAnswer: (id: number) => void;
  selectedAnswer?: number[];
};

export function MediaAnswerItems({
  answers,
  isRanking,
  selectAnswer,
  selectedAnswer = [],
}: MediaAnswerItemsProps) {
  const { isLandscape } = useScreenOrientation();

  return (
    <div
      className={cn(styles.mediaAnswerItems, {
        [styles.isLandscape]: isLandscape,
      })}
    >
      {answers.map(({ body, id, image }: any) => (
        <MediaAnswerItem
          answerId={id}
          body={body}
          isRanking={isRanking}
          image={image}
          key={id}
          selectAnswer={selectAnswer}
          selectedAnswer={selectedAnswer}
        />
      ))}
    </div>
  );
}
