import { Dispatch, SetStateAction } from 'react';

import { AD_WRAPPER_EVENTS } from 'constant/adWrapperEvents';
import ELEMENT from 'constant/element';

import { ParticipantVI, QuestionVI } from 'types/campaign';
import { QUESTION_TYPES } from 'types/constants/questionTypes';

import { Message } from 'hooks/useAdWrapper';

import { DataFunction } from 'components/api/useApi/types';

type SubmitVoteProps = {
  currentQuestion: QuestionVI;
  lastQuestion: QuestionVI;
  nextQuestion: (newQuestionAnswers?: any) => void;
  notifyAdWrapper: (event: string | Message) => void;
  questionAnswers: { [id: string]: any };
  sendData: DataFunction;
};

type SubmitParticipantProps = {
  notifyAdWrapper: (event: string | Message) => void;
  participant: ParticipantVI;
  questions: QuestionVI[];
  sendData: DataFunction;
  setCurrentElement: Dispatch<SetStateAction<string>>;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  setParticipant: Dispatch<SetStateAction<ParticipantVI>>;
};

const sendAnswers = (
  actionName: QUESTION_TYPES,
  { id }: { id: QuestionVI['id'] },
  questionAnswers: { [id: string]: any },
  sendData: DataFunction
) => {
  sendData(`question_${id}`, {
    path: `question_answers/${actionName}`,
    urlParams: `qa[q]=${id}&qa[a]=${questionAnswers[id].join(',')}`,
  });
};

const sendMultiLevelAnswers = (
  actionName: QUESTION_TYPES,
  { id }: { id: QuestionVI['id'] },
  questionAnswers: { [id: string]: any },
  sendData: DataFunction
) => {
  const questionAnswersForElement = questionAnswers[id];

  const questionAnswersArray = Object.entries(questionAnswersForElement)
    .map(entry => entry.join(':'))
    .map(value => value.replace(/,/g, ';'));

  sendData(`question_${id}`, {
    path: `question_answers/${actionName}`,
    urlParams: `qa[q]=${id}&qa[a]=${encodeURIComponent(
      questionAnswersArray.join(',')
    )}`,
  });
};

const sendVerbatim = (
  id: number,
  questionAnswers: { [id: string]: any },
  sendData: DataFunction
) => {
  const textValue = questionAnswers[id].join('±');

  sendData(`question_${id}`, {
    path: 'question_answers/verbatim',
    urlParams: `qa[q]=${id}&qa[a]=${encodeURIComponent(textValue)}`,
  });
};

const sendNps = (
  { id }: { id: QuestionVI['id'] },
  questionAnswers: { [id: string]: any },
  sendData: DataFunction
) => {
  sendData(`question_${id}`, {
    path: 'question_answers/nps',
    urlParams: `qa[q]=${id}&qa[a]=${encodeURIComponent(questionAnswers[id])}`,
  });
};

export const submitVote = ({
  currentQuestion,
  lastQuestion,
  nextQuestion,
  notifyAdWrapper,
  questionAnswers,
  sendData,
}: SubmitVoteProps) => {
  const { questionType } = currentQuestion;

  switch (questionType) {
    case QUESTION_TYPES.verbatim:
      sendVerbatim(currentQuestion.id, questionAnswers, sendData);
      break;
    case QUESTION_TYPES.nps:
      sendNps(currentQuestion, questionAnswers, sendData);
      break;
    case QUESTION_TYPES.simple:
      sendAnswers(
        QUESTION_TYPES.simple,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    case QUESTION_TYPES.multi:
      sendAnswers(
        QUESTION_TYPES.multi,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    case QUESTION_TYPES.ranking:
      sendAnswers(
        QUESTION_TYPES.ranking,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    case QUESTION_TYPES.radar:
      sendMultiLevelAnswers(
        QUESTION_TYPES.radar,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    case QUESTION_TYPES.wordPairs:
      sendMultiLevelAnswers(
        QUESTION_TYPES.wordPairs,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    case QUESTION_TYPES.matrix:
      sendMultiLevelAnswers(
        QUESTION_TYPES.matrix,
        currentQuestion,
        questionAnswers,
        sendData
      );
      break;
    default:
      // send nothing
      break;
  }

  nextQuestion(questionAnswers);

  notifyAdWrapper({
    payload: { question_id: currentQuestion.id },
    type: AD_WRAPPER_EVENTS.questionSubmitted,
  });

  if (lastQuestion && lastQuestion.id === currentQuestion.id) {
    notifyAdWrapper(AD_WRAPPER_EVENTS.lastQuestionSubmitted);
  }
};

const sendParticipantEmail = ({
  notifyAdWrapper,
  participant,
  sendData,
}: {
  notifyAdWrapper: (event: string | Message) => void;
  participant: ParticipantVI;
  sendData: DataFunction;
}) => {
  sendData('email', {
    path: 'participants/update_email',
    urlParams: `p[email]=${encodeURIComponent(participant.email)}`,
  });

  notifyAdWrapper(AD_WRAPPER_EVENTS.emailSubmitted);
};

export const submitParticipant = ({
  notifyAdWrapper,
  participant,
  questions,
  sendData,
  setCurrentElement,
  setCurrentIndex,
  setParticipant,
}: SubmitParticipantProps) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      participant.email.trim()
    )
  ) {
    sendParticipantEmail({ notifyAdWrapper, participant, sendData });
    setCurrentElement(ELEMENT.message);
    setCurrentIndex(questions.length + 1);
    setParticipant(state => ({ ...state, emailValid: true }));
  } else {
    setParticipant(state => ({ ...state, emailValid: false }));
  }
};

export default { submitParticipant, submitVote };
