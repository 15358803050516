import { memo } from 'react';

import cn from 'classnames';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { GdprButton } from './gdprButton';
import { BUTTON_TYPE, NavigationButton } from './navigationButton';

import styles from './footer.module.scss';

type FooterProps = {
  isParticipantElement: boolean;
  next: () => false | undefined;
  nextDisabled: boolean;
  nextHidden: boolean;
  prev: () => void;
  prevReady: boolean;
};

const Footer = ({
  isParticipantElement,
  next,
  nextDisabled,
  nextHidden,
  prev,
  prevReady,
}: FooterProps) => {
  const { isLandscape } = useScreenOrientation();
  return (
    <hdm-center class={styles.centered}>
      <hdm-stack
        id="footer-buttons"
        class={cn(styles.buttonsContainer, {
          [styles.isLandscape]: isLandscape,
        })}
      >
        <NavigationButton
          type={BUTTON_TYPE.previous}
          navigate={prev}
          hidden={!prevReady || isParticipantElement}
        />
        <GdprButton />
        <NavigationButton
          type={BUTTON_TYPE.next}
          navigate={next}
          hidden={nextHidden}
          disabled={nextDisabled}
        />
      </hdm-stack>
    </hdm-center>
  );
};

const MemoFooter = memo(Footer);

export { MemoFooter as Footer };
