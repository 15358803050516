import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

type PixelImageProps = {
  src: string;
};

function PixelImage({ src }: PixelImageProps) {
  const [tryCount, setTryCount] = useState<number>(1);

  const timeout = useRef<null | NodeJS.Timeout>(null);

  const onError = useCallback(() => {
    // Exponential delay to prevent overflowing the API with failing calls
    const delay = Math.exp(tryCount - 1) * 1000;

    timeout.current = setTimeout(() => {
      setTryCount(currentTryCount => currentTryCount + 1);
    }, delay);
  }, [tryCount]);

  const srcWithTryCount = useMemo<string>(() => {
    const url = new URL(src);

    url.searchParams.append('try_count', tryCount.toString(10));

    return url.toString();
  }, [src, tryCount]);

  useEffect(() => {
    setTryCount(1);

    return () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
      }
    };
  }, [src]);

  return (
    <img
      data-testid="pixel-image"
      alt="data"
      onError={onError}
      src={srcWithTryCount}
      style={{ display: 'none' }}
      width="1"
      height="1"
    />
  );
}

const MemoPixelImage = memo(PixelImage);

export { MemoPixelImage as PixelImage };
