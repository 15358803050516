import { useCallback, useEffect, useState } from 'react';

export function useScreenOrientation() {
  const getIsLandscape = useCallback(
    () => window.innerWidth > window.innerHeight,
    []
  );

  const getIsPortrait = useCallback(
    () => window.innerHeight > window.innerWidth,
    []
  );

  const [isLandscape, setIsLandscape] = useState(getIsLandscape());
  const [isPortrait, setIsPortrait] = useState(getIsPortrait());

  const handleOrientationChange = useCallback(() => {
    const newIsLandscapeState = getIsLandscape();

    if (newIsLandscapeState !== isLandscape) {
      setIsLandscape(newIsLandscapeState);
    }

    const newIsPortraitState = getIsPortrait();
    if (newIsPortraitState !== isPortrait) {
      setIsPortrait(newIsPortraitState);
    }
  }, [getIsLandscape, isLandscape, getIsPortrait, isPortrait]);

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener('load', handleOrientationChange, false);
    window.addEventListener('resize', handleOrientationChange, false);

    return () => {
      window.removeEventListener('resize', handleOrientationChange, false);
      window.removeEventListener('load', handleOrientationChange, false);
    };
  }, [handleOrientationChange]);

  return { isLandscape, isPortrait };
}
