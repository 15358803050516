import cn from 'classnames';

import styles from './rankingNumber.module.scss';

type RankingNumberProps = {
  selectedAnswer: number[];
  answerId: number;
  className?: string;
};
export const RankingNumber = ({
  selectedAnswer,
  answerId,
  className,
}: RankingNumberProps) => (
  <div className={cn(styles.rankingNumber, className)}>
    {selectedAnswer.indexOf(answerId) + 1}
  </div>
);
