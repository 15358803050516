import { memo } from 'react';

import { CampaignVI } from 'types/campaign';

import { DataFunction } from 'components/api/useApi/types';

import { IntroScreen } from './introScreen';
import { RecruitmentScreen } from './recruitmentScreen';
import { SurveyScreen } from './surveyScreen';

import { useRootNavigation } from './useRootNavigation';

type RootNavigationProps = {
  campaign: CampaignVI;
  interactive: boolean;
  isParticipantRecruited: boolean;
  preview: boolean;
  redirectionUrl?: string;
  sendData: DataFunction;
  skipData: DataFunction;
};

function RootNavigation({
  campaign,
  interactive,
  isParticipantRecruited,
  preview,
  redirectionUrl,
  sendData,
  skipData,
}: RootNavigationProps) {
  const { introScreen, recruitmentScreen } = campaign;

  const { introCallback, step } = useRootNavigation({
    introScreen,
    preview,
    sendData,
  });

  if (redirectionUrl && !preview && recruitmentScreen) {
    return (
      <RecruitmentScreen
        interactive={interactive}
        recruitmentScreen={recruitmentScreen}
        redirectionUrl={redirectionUrl}
      />
    );
  }

  return step === 'introduction' && !isParticipantRecruited ? (
    <IntroScreen callback={introCallback} campaign={campaign} />
  ) : (
    <SurveyScreen campaign={campaign} sendData={sendData} skipData={skipData} />
  );
}

const MemoRootNavigation = memo(RootNavigation);

export { MemoRootNavigation as RootNavigation };
