import { memo } from 'react';
import { createPortal } from 'react-dom';

import cn from 'classnames';

import { useTranslations } from 'hooks/useTranslations';

import styles from './fullscreenImage.module.scss';

type FullscreenImageProps = { close: () => void; hiding: boolean; src: string };

function FullscreenImage({ close, hiding, src }: FullscreenImageProps) {
  const { t } = useTranslations();

  const domNode = document.getElementById('fullscreen-image-container');

  if (domNode === null) return null;

  return createPortal(
    <button
      type="button"
      onClick={e => {
        close();
        e.stopPropagation();
      }}
      className={cn(styles.fullscreenImage, { [styles.hiding]: hiding })}
    >
      <div className={styles.imageContainer}>
        <div className={styles.innerContainer}>
          <img alt="media" src={src} />
        </div>
      </div>
      <p>{t('close')}</p>
    </button>,
    domNode
  );
}

const MemoFullscreenImage = memo(FullscreenImage);

export { MemoFullscreenImage as FullscreenImage };
