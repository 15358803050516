import { useCallback, useEffect, useRef, useState } from 'react';

export const useVerbatim = ({
  isFocused,
  multipleVerbatim,
  onChange,
  onSubmit,
  position,
  verbatim,
}: {
  isFocused: boolean;
  multipleVerbatim: boolean;
  onChange: (text: any, position?: any) => void;
  onSubmit: () => void;
  position: any;
  verbatim: any;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [clickedElement, setClickedElement] = useState<HTMLElement | null>(
    null
  );

  // Submit only if a text is entered and if we have not multiple verbatims => autoswitch
  const handleBlur = useCallback(
    e => {
      if (
        multipleVerbatim ||
        !isFocused ||
        verbatim.length === 0 ||
        !e.target.contains(clickedElement)
      )
        return;
      onSubmit();
    },
    [clickedElement, isFocused, multipleVerbatim, onSubmit, verbatim.length]
  );

  const handleChange = useCallback(
    e => onChange(e.target.value, position),
    [onChange, position]
  );

  const handleClick = useCallback(
    e => setClickedElement(e.target),
    [setClickedElement]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (verbatim.length === 0) return;
      if (!multipleVerbatim) ref.current?.blur();
      onSubmit();
    },
    [multipleVerbatim, onSubmit, verbatim.length]
  );

  // If the key Enter or Tab are pressed, the focus is lost. The virtual keyboard is closed.
  const handleEnterKeyPressed = useCallback((e: KeyboardEvent) => {
    if (['Enter', 'Tab'].includes(e.code)) ref.current?.blur();
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    setTimeout(() => {
      ref.current?.focus();
      ref.current?.click();
    }, 200);
  }, [isFocused, ref]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    const verbatimInput = ref.current;
    if (verbatimInput)
      verbatimInput.addEventListener('keydown', handleEnterKeyPressed);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      if (verbatimInput)
        verbatimInput.removeEventListener('keydown', handleEnterKeyPressed);
    };
  }, [handleClick, handleEnterKeyPressed]);

  return { handleBlur, handleChange, handleClick, handleSubmit, ref };
};
