import cn from 'classnames';
import _ from 'lodash';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { Media } from '../media';
import { MediaAnswerItem } from '../shared/answerItems/mediaAnswerItems/mediaAnswerItem';
import { LevelButtons } from '../shared/levelButtons';
import { Question } from '../shared/question';

import styles from './radarQuestion.module.scss';

const RADAR_MAX = 5;

type RadarQuestionProps = {
  active: boolean;
  audio?: string;
  audioType: string;
  answers: AnswerVI[];
  currentAnswer: any;
  body: string;
  hint?: string;
  image?: string;
  selectAnswer: (index: number, value: any) => void;
  video?: string;
};

const RadarQuestion = ({
  active,
  audio,
  audioType,
  answers,
  currentAnswer,
  body,
  hint,
  image,
  selectAnswer,
  video,
}: RadarQuestionProps) => {
  const hasMedia = !!(audio || video || image);
  const { isLandscape } = useScreenOrientation();
  const hasImageAnswers = _.some(answers, 'image');

  return (
    <Question
      body={body}
      hint={hint}
      className={cn({
        [styles.twoColumns]: hasMedia && isLandscape && !hasImageAnswers,
      })}
    >
      <Media
        active={active}
        audio={audio}
        audioType={audioType}
        image={image}
        video={video}
      />
      <div
        className={cn(styles.radarAnswers, {
          [styles.hasImageAnswers]: hasImageAnswers,
        })}
      >
        {answers.map(
          ({ body: answerBody, id, image: answerImage }: AnswerVI) => (
            <hdm-stack key={id.toString()} class={styles.answer}>
              {!_.isNil(answerImage) ? (
                <MediaAnswerItem
                  className={styles.imageAnswer}
                  disabled
                  answerId={id}
                  body={answerBody}
                  image={answerImage}
                />
              ) : (
                <p className={styles.answer}>{answerBody}</p>
              )}
              <LevelButtons
                className={styles.levelGroups}
                min={0}
                max={RADAR_MAX}
                currentAnswer={currentAnswer[id]}
                selectLevel={(level: number) => selectAnswer(id, level)}
              />
            </hdm-stack>
          )
        )}
      </div>
    </Question>
  );
};

export default RadarQuestion;
