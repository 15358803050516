import { memo } from 'react';

import styles from './Counter.module.scss';

type CounterProps = {
  dimensionsCount: number;
  index: number;
};

const Counter = ({ dimensionsCount, index }: CounterProps) => {
  return (
    <span className={styles.dimensionCounter}>
      {`(${index + 1}/${dimensionsCount})`}
    </span>
  );
};

const CounterMemo = memo(Counter);

export { CounterMemo as Counter };
