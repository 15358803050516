import { useMemo } from 'react';

import cn from 'classnames';

import { AnswerVI } from 'types/campaign';

import { useScreenOrientation } from 'hooks/useScreenOrientation';

import { MediaAnswerItems } from './mediaAnswerItems';
import { TextAnswerItems } from './textAnswerItems';

import styles from './answerItems.module.scss';

type AnswerItemsProps = {
  answers: AnswerVI[];
  isRanking?: boolean;
  hasMedia?: boolean;
  oneColumn?: boolean;
  selectAnswer: (answerId: any) => void;
  selectedAnswer: any[];
};

export function AnswerItems({
  answers,
  isRanking,
  hasMedia = false,
  selectAnswer,
  selectedAnswer,
  oneColumn = false,
}: AnswerItemsProps) {
  const { isLandscape } = useScreenOrientation();
  const answersHaveImage = useMemo(
    () =>
      answers.find(({ image: imageAnswer }: any) => imageAnswer) !== undefined,
    [answers]
  );

  return answersHaveImage ? (
    <MediaAnswerItems
      answers={answers}
      isRanking={isRanking}
      selectAnswer={selectAnswer}
      selectedAnswer={selectedAnswer}
    />
  ) : (
    <TextAnswerItems
      className={cn({
        [styles.isOneColumnAnswers]: oneColumn || (hasMedia && isLandscape),
      })}
      answers={answers}
      isRanking={isRanking}
      selectAnswer={selectAnswer}
      selectedAnswer={selectedAnswer}
    />
  );
}
